<template>  
<div class="container">

  <v-app-bar app style="top:0;">
			<v-toolbar-items>
				<v-btn icon @click="$router.back()">
				  <v-icon>mdi-arrow-left-thick</v-icon>
			  </v-btn>
			</v-toolbar-items>
			<v-toolbar-title> {{notice.topic}}</v-toolbar-title>
			<v-spacer></v-spacer>
  </v-app-bar>

  <div class="white-card" style="margin-top:50px;">
    <p style="text-align: left; white-space: pre-line; padding:3px;">{{notice.info}}</p>
  </div>
</div>
</template>

<style scoped>

</style>

<script>
  import axios from "../../util/axios";
  const qs = require("qs");

  export default {
    name: "notice",
    data: function () {
      return {
        email: "",
        password: "",
        password_confirmation: "",
        message: "",
        client: this.$route.query.client,
        uid: this.$route.query.uid,
        access_token: this.$route.query.token,
        notice:[],
      };
    },
    mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.getNotice()
    },
    methods: {
      async getNotice() {
        // const result = await axios.get("https://matching.itonami.design/api/notice?id=" + this.$route.params.id, {
          const result = await axios.get("/api/notice?id=" + this.$route.params.id, {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          console.error(e)
        });

        this.notice = result.data.notification
      },
      async post() {
        // const self = this;
        const result = await axios
          .put("/api/auth/password", {
            uid: this.uid,
            credentials: 'include', //add
            "access-token": this.access_token,
            client: this.client,
              password: this.password,
              password_confirmation: this.password_confirmation,
              // redirect_url: "localhost:3001/home",
            
            paramsSerializer: function (params) {
              return qs.stringify(params, { arrayFormat: "brackets" });
            },
          })
          .catch((e) => {
            console.error(e);
          });

        if (!result) {
          this.message = "エラーが発生しました。";
          return;
        }
        // if (!result.data) {
        //   this.message = "エラーが発生しました。";
        //   return;
        // }

        if (result) {
          //結果を基にページ遷移
          this.message="パスワードの変更が完了しました。"
          this.$router.push("/login");
        }
      },
    },
  };
</script>