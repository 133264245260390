<template>
  <div class="recommends container content-wrapper" style="padding-top:10px;">
    <div class="container" v-if="change_success==false">
      <div>
        <v-app>
          <v-container>
            
            <v-form ref="al_form" :lazy-validation="true">
              <h2>カード番号{{ site_id }}</h2>
              <v-row>
                <v-col cols="2">
                  <img src="@/assets/cards/visa.png" style="width:100%;">
                </v-col>
                <v-col cols="2">
                  <img src="@/assets/cards/master.png" style="width:100%;">
                </v-col>
                <v-col cols="2">
                  <img src="@/assets/cards/jcb.png" style="width:100%;">
                </v-col>
                <v-col cols="2">
                  <img src="@/assets/cards/diners.png" style="width:100%;">
                </v-col>
              </v-row>
              <v-text-field
                v-model="al_form.no"
                placeholder="XXXXXXXXXXXXXXX"
                :rules="[required, card_rule]"
                autocomplete="cc-number"
                />
              <h2>カード名義</h2>
              <v-text-field
                v-model="al_form.name"
                placeholder="SEIHEKI CHANG"
                :rules="[required]"
                autocomplete="cc-name"
                />
              <h2>有効期限</h2>
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="al_form.expm"
                    placeholder="MM"
                    :items="months"
                    :rules="[required,mm_rule]"
                    autocomplete="cc-exp-month"
                    />
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="al_form.expy"
                    placeholder="YY"
                    :items="years"
                    :rules="[required,yy_rule]"
                    autocomplete="cc-exp-year"
                    />
                </v-col>
              </v-row>
              <h2>CVV2</h2>
              <v-text-field
                v-model="al_form.cvv2"
                placeholder="XXX"
                :rules="[required,cvv2_rule]"
                autocomplete="cc-csc"
                />
            </v-form>
            <div v-show="isLoading" style="padding-top:10px;">
              <Spinner></Spinner>
            </div>
            <div v-show="!this.isLoading">
              <p style="font-weight: 900;">{{message}}</p>
              <v-btn @click='purchase()' class="btn-comp login-btn">カード情報変更</v-btn>
            </div>
          </v-container>
        </v-app>
      </div>

    </div>
    <div class="container" v-if="change_success==true">
      <div v-show="!this.isLoading">
        <p style="font-weight: 900;">{{message}}</p>
        <v-btn @click="$router.push('/home')" class="btn-comp login-btn">ホーム</v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .btn-comp{
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 30px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }
  
  .login-btn{
background: rgb(248,70,129);
background: linear-gradient(90deg, rgba(248,70,129,1) 0%, rgba(255,150,145,1) 100%);
  }
  label{
    border-left: 5px solid ;
    border-image: linear-gradient(0deg, rgba(96,33,220,1) 0%, rgba(248,132,140,1) 100%);
    border-image-slice: 0 0 0 5;
    padding-left: 10px;
    font-size: 0.8rem;
    font-weight: 900;
    color: #4B4B4B;
    text-align: left;
    margin-top: 30px;
  }
</style>

<script>
  import axios from "../../util/axios";
  import Spinner from 'vue-simple-spinner'
  export default {
    name: "ChangeCard",
  components:{
    Spinner
  },
    data() {
      return {

        isLoading: true,
        completedSteps: 0,
        
        uid: "",
        client: "",
        access_token: "",
        email: "",
        twitterid: "",
        info:"",
        info_ts: "",
        payment_conf: "",
        payment_kind: 0,
        payment_resource:'',
        age_conf: "",
        data_all: "",
        rank: "-",
        sex: "",
        matching_count: 0,
        recommend_count: 0,
        pp:'al',
        site_id:'',
        al_form:{
          no:'',
          name:'',
          expm:'',
          expy:'',
          cvv2:''
        },
        card_rule:
        v => /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6011[0-9]{12}|3(?:0[0-5]|[68][0-9])[0-9]{11}|3[47][0-9]{13}|(?:2131|1800|35[0-9]{3})[0-9]{11})$/.test(v) || "有効なカード情報を入力してください",
        required:
          v => !!v || "項目を入力してください",
        yy_rule:
          v => /\d{2}/.test(v) || "有効な年を入力してください",
        mm_rule:
          v => /^(0?[1-9]|1[0-2])$/.test(v) || "有効な月を入力してください",
        cvv2_rule:
          v => /^[0-9]{3,4}$/.test(v) || 'CVV2コードは3桁または4桁の数字で入力してください。',
        // months:['01','02','03','04','05','06','07','08','09','10','11','12'],
        months:[1,2,3,4,5,6,7,8,9,10,11,12],
        years:[2023,2024,2025,2026,2027,2028,2029,2030,2031,2032,2033,2034],
        al_form_is_valid:false,
        token:'',
        message:'決済情報を入力してください',
        al_base_url: process.env.VUE_APP_AL_BASE_URL,
        al_change_card_url: "",
        change_success: false,
      };
    },
    mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.getAccountData();
      if (this.uid===null){
        this.$router.push('/login');
      }
    },
    methods: {
      callback(result) {
      if (result.token != null) {
        this.token = result.token;
        this.post()
      } else {
          //エラー処理
          this.isLoading = !this.isLoading;
          alert('カード情報を正しく入力してください');
          console.log(result)
      }
    },
    purchase() {
      this.isLoading = !this.isLoading;

      // ここのせいでうまくいかない
      // if(this.plan==5||this.plan==9){
      //   this.site_id = "50130901"
      // }else if(this.plan==6||this.plan==10){
      //   this.site_id = "50130902"
      // }else if(this.plan==7||this.plan==11){
      //   this.site_id = "50130903"
      // }else if(this.plan==8||this.plan==12){
      //   this.site_id = "50130904"
      // }

      window.TokenPay.init(this.site_id);
      window.TokenPay.createToken({
        number: this.al_form.no, //カード番号
        name: this.al_form.name, //カード名義
        expMonth: this.al_form.expm, //カード有効期限(月)
        expYear: this.al_form.expy, //カード有効期限(年)
        cvv2: this.al_form.cvv2 //カードCVV2
      }, this.callback); //コールバック関数(トークン取得後に実行)
    },
    async getAccountData() {
      const result = await axios.get("/api/payment_information", {
        headers: {
          uid: this.uid,
          "access-token": this.access_token,
          client: this.client,
        },
      }).catch((e) => {
        console.error(e);
      });

      if (!result) {
        // エラーの場合ログイン画面へ遷移させる
        // this.redirectLogin();
        return;
      }
      this.payment_id = result.data.payment_id;
      this.payment_pass = result.data.payment_pass;
      this.payment_conf= result.data.payment_conf;
      this.payment_kind=result.data.payment_kind;
      this.last_payment_date=result.data.last_payment_date;
      this.next_payment_date=result.data.next_payment_date;
      this.course_name=result.data.course_name;
      this.email = result.data.email;
      this.site_id = result.data.site_id

      if (this.matching_count==null){
        this.matching_count = 0;
      }
      if (this.recommend_count == null){
        this.recommend_count = 0;
      }
      this.isLoading = false;
      setTimeout(this.setCompletedSteps, 1000);
    },
    async post() {
      const result = await axios({
      method : 'POST',
      url    : '/api/al/cards/change',
      headers: {'Content-Type': 'application/json',
              uid: this.uid,
              "access-token": this.access_token,
              client: this.client,
            },
      data   : { 
            pi:{
              token:this.token,
              site_id:this.site_id,
              campaign:this.listed,
              uid:this.email
            }
        }
      }).catch((e) => {
        e
        this.isLoading = !this.isLoading;
      });

      if(!result.data.success){
        this.message = "入力内容を確認してください"
        this.message = result.data.msg
        this.isLoading = !this.isLoading;
      }
      if(result.data.success) {
        this.post_content="";
        this.al_form.no = '';
        this.al_form.name = '';
        this.al_form.expm = '';
        this.al_form.expy = '';
        this.al_form.cvv2 = '';
        // this.getAccountData();
        // this.$router.push({ name: 'Home'});
        this.isLoading = !this.isLoading;
        this.change_success = true;
        this.message = "更新しました";
      }
    }
  }
}
</script>