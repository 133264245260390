<template>

  
  <div class="container">
  <h2>お知らせ一覧</h2>
  <div v-for="notice in notice_list" :key="notice.id" @click="$router.push('/notice/' + notice.id)">
    <h2>{{notice.topic | omittedText}}</h2>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
  import axios from "../../util/axios";
  const qs = require("qs");

  export default {
    name: "noticeindex",
    data: function () {
      return {
        email: "",
        password: "",
        password_confirmation: "",
        message: "",
        client: this.$route.query.client,
        uid: this.$route.query.uid,
        access_token: this.$route.query.token,
        notice_list: [],
      };
    },
    mounted() {
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.getNoticeList()
    },
    methods: {
      async getNoticeList() {
        // const result = await axios.get("https://matching.itonami.design/api/notice_list", {
          const result = await axios.get("/api/notice_list", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          console.error(e)
        });

        this.notice_list = result.data.notifications
      },
      async post() {
        // const self = this;
        const result = await axios
          .put("/api/auth/password", {
            uid: this.uid,
            credentials: 'include', //add
            "access-token": this.access_token,
            client: this.client,
              password: this.password,
              password_confirmation: this.password_confirmation,
              // redirect_url: "localhost:3001/home",
            
            paramsSerializer: function (params) {
              return qs.stringify(params, { arrayFormat: "brackets" });
            },
          })
          .catch((e) => {
            console.error(e);
          });

        if (!result) {
          this.message = "エラーが発生しました。";
          return;
        }
        // if (!result.data) {
        //   this.message = "エラーが発生しました。";
        //   return;
        // }

        if (result) {
          //結果を基にページ遷移
          this.message="パスワードの変更が完了しました。"
          this.$router.push("/login");
        }
      },
    },
    filters: {
      omittedText(text) {
        if(text == null){
          return text
        }
        else{
          // 11文字目以降は"…"
          return text.length > 20 ? text.slice(0, 20) + "…" : text;
        }
      }
    },
  };
</script>