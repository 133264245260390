<template>
	<div class="recommends container content-wrapper" style="padding-top:10px;">
	  <div v-if="isLoading" style="padding-top:60px;">
		<Spinner></Spinner>
	  </div>
	  <div class="container" v-else>
		
		<div class="row">
			<div class="col-sm-12" style="margin:3px; padding:0px;" v-show="!only_profile">
				<router-link :to="'/hona'">
				<v-container class="white-card recommend-card" style="height:50px; border:1px solid gray;">
					<div style="padding:0 25px">
					<v-row>
					<v-col cols="2" text-align="left" style="justify-content: center; padding:0 !important; margin:0 !important;">
										<img style="max-height:45px;margin-top:10px;" :src="'a'" class="img-fluid rounded-circle" onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';">
					<v-badge
						v-if="admin_message_unread > 0"
						dot
						color="pink"
									style=""
									></v-badge>
								</v-col>
					<v-col cols="10" style="justify-content: center; text-align: left;">
									<span style="font-size:0.6rem;"></span><br>
								マッチン運営事務局
								</v-col>
				</v-row>
				</div>
				</v-container>
				</router-link>
			</div>  
			<div class="col-12"  style="margin:3px; padding:0px;"  v-for="rec in data_all.rec" :key="rec.id" v-show="isViewable(rec)">
				<router-link :to="{ name: 'chat', query: { uuid: rec.uuid }}" v-if="query_params=='matching' && !only_profile">
					<v-container class="white-card recommend-card" style="height:50px;">
						<p v-if="false" class="text-left" style="padding-left:10px;"><span style="font-weight: 900;color:#f85b8c;" v-if="rec.status_n==0">New! </span>{{rec.created_at | moment}}</p>
						<div style="padding:0 25px">
						<v-row>
							<v-col cols="2" text-align="left" style="justify-content: center;">
									<img style="max-height:45px; margin-top:0px;" :src="rec.profile1" class="img-fluid-icon rounded-circle" onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';">
								<v-badge
								v-if="rec.unread_messages_size > 0"
								dot
								color="pink"
								style=""
								></v-badge>
							</v-col>
							<v-col cols="10" style="justify-content: center; text-align: left;">
								
								<span style="font-weight:900;">{{rec.twitter_name}}</span> 
								<span style="font-size:0.6rem;"> {{rec.timestamp}}</span>
								<br>
								<span v-if="rec.last_message != ''"> {{ rec.last_message }}</span>
								<span v-else>新しいマッチング</span>
							</v-col>
						</v-row>
						</div>
					</v-container>
				</router-link>
				
				<router-link :to="{ name: 'ProfileFemale', query: { id: rec.partner_user_id }}" v-if="false">
					<v-container class="white-card recommend-card" style="height:50px;">
						<p v-if="false" class="text-left" style="padding-left:10px;"><span style="font-weight: 900;color:#f85b8c;" v-if="rec.status_n==0">New! </span>{{rec.created_at | moment}}</p>
						<div style="padding:0 25px">
						<v-row>
							<v-col cols="3" text-align="left" style="justify-content: center;">
									<img style="max-height:45px; margin-top:0px;" :src="rec.profile1" class="img-fluid-icon rounded-circle" onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';">
								<v-badge
								v-if="rec.unread_messages_size > 0"
								dot
								color="pink"
								style=""
								></v-badge>
							</v-col>
							<v-col cols="9" style="justify-content: center; text-align: left;">
								<span style="font-size:0.6rem;">{{rec.timestamp}}</span><br>
								{{rec.twitter_name}}
							</v-col>
						</v-row>
						</div>
					</v-container>
				</router-link>
		  	</div>
			  <div class="row" v-show="only_profile">
        <div class="col-6" v-for="rec in data_all.rec" :key="rec.id" v-show="isViewable(rec)">
          <div class="white-card recommend-card">
              <p class="text-left" style="padding-left:10px;"><span style="font-weight: 900;color:#f85b8c;" v-if="rec.status_n==0">New! </span>{{rec.created_at | moment}}</p>
              <div style="padding:0 25px">
              <router-link :to="{ name: 'ProfileFemale', query: { id: rec.partner_user_id }}">
                <img :src="rec.profile1" class="img-fluid-pane rounded-circle" onerror="this.onerror = null; this.src='https://abs.twimg.com/sticky/default_profile_images/default_profile_400x400.png';">
              </router-link>
              </div>
              <p>{{rec.twitter_name}}</p>

            <div v-if="rec.status_n==2 || rec.status_n==9" style="display:flex; justify-content: center;">
              <router-link :to="'/myreviewindex?id='+rec.id" style="padding: 5px 15px;border:none; border-radius:3px; width:80%; justify-content: center; width:80%; margin-bottom:1rem;">
                {{rec.status_n | status_fix}}
              </router-link>
            </div>

            <div v-else style="display:flex; justify-content: center;">
              <p style="padding: 5px 15px;border:none; border-radius:3px; width:80%; justify-content: center;">
                 <Icon name="circle" scale="1" :style="rec.status_n | status_style" />{{rec.status_n | status_fix}}
              </p>
            </div>
          </div>
        </div>

      </div>
			
		</div>
	  <paginate
		:page-count="pageCount"
		:click-handler="clickCallback"
		v-model="currentPage"
		:prev-text="'<<'"
		:next-text="'>>'"
		:containerClass="'pagination'"
		:page-class="'page-item'"
		:page-link-class="'page-link'"
		:prev-class="'page-item'"
		:prev-link-class="'page-link'"
		:next-class="'page-item'"
		:next-link-class="'page-link'"
		>
	  </paginate>
	  </div>
  
  <Icon name="check-square" scale="1" style="color:" v-show=false />
	</div>
  </template>
  
  <style scoped>
	.btn-comp{
	  color: white;
	  border: none;
	  border-radius: 30px;
	  width: 50%;
	  margin-top: 30px;
	  padding: 12px;
	  font-size: 1.2rem;
	  font-weight: 900;
	}
  
	.login-btn{
	  background: rgb(248,70,129);
	  background: linear-gradient(90deg, rgba(248,70,129,1) 0%, rgba(255,150,145,1) 100%);
	}
  
	.recommend-card{
	  /* height: 150px; */
	  width: 100%;
	  text-align: center;
	}
  
	.review-btn{
	  appearance: none;
	  background: #F6F6F6;
	  width: 100%;
	  padding: 5px 15px;
	  border-color: 1px solid d5d5d5 !important;
	}
  
	.review-disabled-btn{
	  appearance: none;
	  background: #fff;
	  color: #d5d5d5;
	  width: 100%;
	  padding: 5px 15px;
	}
  .pagination {
	text-align: center;
	margin: 10 0 0 0;
	padding: 0;
	list-style-type: none;
	justify-content: center;
  }
  .pagination .number {
	display: inline-block;
	width: 50px;
	height: 50px;
	line-height: 48px;
	margin: 0 2px;
	box-sizing: border-box;
	text-align: center;
	border-radius: 5px;
  }
  .pagination .number.active {
	background: #F5811F;
  }
  .pagination .number.active a {
	color: #fff;
  }
  .pagination .number a {
	display: inline-block;
	width: 100%;
	height: 100%;
	text-decoration: none;
  }
  .pagination .number a:focus {
	outline: none;
  } 
  .img-fluid-icon{
  width: 40px !important;
  height: 40px !important;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.img-fluid-pane{
  width: 90px !important;
  height:90px !important;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
  </style>
  
  <script>
  
  import moment from "moment";
  import axios from "../../util/axios";
  import 'vue-awesome/icons'
  import Icon from 'vue-awesome/components/Icon'
  import Spinner from 'vue-simple-spinner'
  import Paginate from 'vuejs-paginate'
  export default {
  
	name: 'Recommends',
	  data() {
		return {
		  data_all:"",
		  uid: "",
		  client: "",
		  access_token: "",
		  query_params: "",
		  isLoading: false,
		  pageCount: 1,
		  currentPage: 1,
		  admin_message_unread:0,
		  only_profile:false,
		};
	  },
	  filters: {
		  moment:function(date){
			  return moment(date).format('YYYY/MM/DD');
		  },
		  status_fix:function(data){
			var txt=""
			if(data==0){
			  txt="レコメンド"
			} else if(data==1){
			  txt="マッチン"
			} else if(data==2){
			  txt="マッチン"
			} else if(data==3){
			  txt="ノーマッチン"
			} else if(data==4){
			  txt="マッチン"
			} else if(data==6){
			  txt="保留中"
			} else if(data==7){
			  txt="マッチン"
			}
			return txt
		  },
		  status_style:function(data){
			var txt=""
			if(data==0){
			  txt="color: rgb(117,83,223);"
			} else if(data==1){
			  txt="color: rgb(235,72,114);"
			} else if(data==2){
				txt="color: rgb(235,72,114);"
			} else if(data==3){
			  txt="color: rgb(101,101,129);"
			} else if(data==4){
				txt="color: rgb(235,72,114);"
			} else if(data==6){
			  txt="color: rgb(70,70,70);"
			} else if(data==7){
				txt="color: rgb(235,72,114);"
			}
			return txt
		  }
	  },
	  methods: {
		isViewable(rec) {
		  if (this.query_params != "matching") {
			return [0,1,2,3,4,6,7,8,9].includes(rec.status_n)  
		  } else {
			return [1,2,4,7].includes(rec.status_n)
		  }
		  
		},
		isReviwable(rec) {
		  const status = rec.status_n
  
		  // if (((status==1) || (status==2)) && rec.is_reviewable){
		  if (((status==1) || (status==2)) && true){
			return true
		  }else{
			return false
		  }
  
		},
		isChoosable(rec) {
		  if(rec.is_choosable && rec.status_n==1) {
			return true
		  } else {
			return false
		  }
		},
	  clickCallback: function(pageNum) {
		this.getAccountData(pageNum);
	  },
		async getAccountData(page=1) {
		  this.isLoading=true;
		  this.currentPage=page;
		  const result = await axios.get("/api/recommends/male"+"?status="+this.$route.query.status+"&page="+page, {
			headers: {
			  uid: this.uid,
			  "access-token": this.access_token,
			  client: this.client,
			},
		  }).catch((e) => {
			e
		  });
		  this.isLoading=false;
		  if (!result) {
			// エラーの場合ログイン画面へ遷移させる
			// this.redirectLogin();
			return;
		  }
		  this.data_all = result.data;
		  this.pageCount = this.data_all.pageCount
		  this.admin_message_unread = result.data.admin_message_unread
		},
		async submitNoReplied(rec){
		  this.isLoading = true;
		  const result = await axios({
			method : 'POST',
			url    : "/api/recommend/noreplied",
			headers: {'Content-Type': 'application/json',
					uid: this.uid,
					"access-token": this.access_token,
					client: this.client,
				  },
			data: {
			  recommend:{
				id: rec.id,
			  }}
		  }).catch((e) => {
			e
			this.$router.push('/login');
		  });
  
		  if (!result) {
			// エラーの場合ログイン画面へ遷移させる
			this.$router.push('/login');
			this.isLoading = false;
			return;
		  }
		  if (result) {
			this.$router.push('home');
			return;
		  }
		  this.isLoading = false;
		},
		
		redirectLogin() {
		  //ページ遷移
		  this.$router.push("/login");
		},
	  },
	  async mounted() {
		this.uid = localStorage.getItem('uid')
		this.client = localStorage.getItem('client')
		this.access_token = localStorage.getItem('access-token')
		this.query_params = this.$route.query.status
		this.only_profile = this.$route.query.profile_list
		this.getAccountData();
		if (this.uid===null){
		  this.$router.push('/login');
		}
		window.scrollTo({top: 0});
	  },
	
	
	
	components:{
	  Icon,
	  Spinner,
	  Paginate
	}
	}
  </script>
  