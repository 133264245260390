<template>
<div class="circle-wrapper">

<radial-progress-bar :diameter="150" :completed-steps="completedSteps" :total-steps="totalSteps" :animateSpeed="1000" :startColor="startColor" :stopColor="stopColor" :strokeWidth="15" :innerStrokeWidth="15" :innerStrokeColor="innerStrokeColor"
:timingFunc="timingFunc" style="position:absolute; margin:auto;top:0;right:0;left:0;bottom:0;"
></radial-progress-bar>
		<div style="position:absolute; text-align: center;font-size:10px;font-weight: bold; margin:auto; top:0; bottom:0; right:0; left:0; vertical-align: middle; width:50%; height:50%">
				<span class="rank-view">{{rank}}</span>
	</div>


	</div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress'

	export default {
		name: "HalfRanktile",
		components: {
			RadialProgressBar,
		},
		props: ["rank", "completedSteps", ],
		data() {
			return {
				max: 5,
				now: 1,
				// completedSteps: 0,
				totalSteps:5,
				startColor:"#755AE7",
				stopColor:"#FA9E98",
				innerStrokeColor:"#D2D1EE",
				// timingFunc:"cubic-bezier(.29, 1.01, 1, -0.68)",
				timingFunc:"cubic-bezier(.68, 0.55, .265, 1.35)"

				// rank: "D"
			};
		},
		computed:{
			currentAngle(){
				return Math.floor(360*(this.now/this.max));
			},
			rightAngle(){
				let angle = Math.min(this.currentAngle, 180);
					return {
					"transform": "rotate(" + angle + "deg)",
				}
			},
			leftAngle(){
				let angle = Math.min(Math.max(this.currentAngle-180, 0),180);
				return {
				"transform": "rotate(" + angle + "deg)",
				}
			},
		}
	}

</script>

<style scoped>
	.ranktile{
		background: white;
		border-radius: 5px;

		height: 200px;
		position: relative;
	}

	.circle-wrapper{
		left: 0;
		right: 0;
		margin: auto;
		width: 125px;
		max-width: 40vw;

	}
	.square{
		position:absolute;
		width:62.5px;
		max-width: 20vw;
		height:125px;

		
		overflow:hidden;
	}
	.rank-view{
		color: #6C35D9;
		font-size: 62.5px;
		line-height: px;
		height: 100%;
		font-weight: 900;
		display: block;
		margin: 0 auto;
		vertical-align: middle;
  vertical-align: middle;
  justify-content: center;
  display: flex;
  align-items: center;

	}


</style>