<template>
  <div class="home container" style="padding-top: 10px ;padding-bottom:100px;">
    <div v-show="isLoading" style="padding-top:60px;">
      <Spinner></Spinner>
    </div>
    <transition name="fade">
      <particles-bg type="fountain" :num="5" :bg="true" :zindex="10" :config=config v-show="rankup_flag"/>
    </transition>
    <div  v-show="!isLoading && active_status!='inactive'">
    <div>
      <div v-if="sex==1">
        <div style="text-align:center;" v-show="false">
          <router-link to="/rankrequest" class="small-link">
          <button class="btn-rank-request">
            ランク付け申請ページへ進む
          </button>
          </router-link>
          <p>※一週間に一度しか行えません。</p>
        </div>
      </div>
    </div>
    <div class="container white-card">
    <h2 style="margin-top:0;">現在のランク</h2>
      <div class="container" style="position:relative; height:200px;">
        <HalfRanktile :rank="rank" :completedSteps="completedSteps"/>
      </div>
      <div>
        <transition name="fade">
          <div v-show="rankup_flag">
            <p v-show="rankup_flag" class="rankup-text" style="font-size:3rem; font-weight:900;">Rank UP!</p>
          </div>
        </transition>
        <transition name="fade">
          <div style="display:flex; flex-direction:row;" v-show="!rankup_flag">
            <img :src="require('@/assets/seiheki-chan.jpg')" style="width:100px; height:100px; border-radius:50px; border:3px solid white;">

            <div style="height:100px; align-items: center; vertical-align: middle; display:flex;">
              <p style="padding-left:15px; font-weight: 900; text-align: left; margin-bottom:0;">ランクアップして女性にモテるアカウントを作っていきましょう！💕</p>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div>
      
    </div>
    <div v-show="rank=='H'">
      <h2>プロフィール入力をしましょう！</h2>
      <v-btn @click="completedSteps=5; completedSteps=1; rank='G'; $router.push('/partialform')">入力画面へ</v-btn>
    </div>
    
    <div v-show="rank=='G'">
      <h2>動画を観てランクを上げましょう！ ({{tutorial_movie_progress+1}}/5)</h2>
      <div class="container white-card">
        <div v-if="tutorial_movie1 == true"> 
          <h2>動画1</h2>
          <iframe width="100%" class="tutorial-movie" src="https://www.youtube.com/embed/ZQvZVbAv80M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <v-btn @click="tutorial_movie1=!tutorial_movie1; tutorial_movie2=!tutorial_movie2; tutorial_movie_progress+=1; completedSteps+=1">視聴完了</v-btn>
        </div>
        <div v-if="tutorial_movie2 == true">
          <h2>動画2</h2>
          <iframe width="100%" class="tutorial-movie" src="https://www.youtube.com/embed/3_E1x3MWFXk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <v-btn @click="tutorial_movie2=!tutorial_movie2; tutorial_movie3=!tutorial_movie3; tutorial_movie_progress+=1; completedSteps+=1">視聴完了</v-btn>
        </div>
        <div  v-if="tutorial_movie3 == true">
          <h2>動画3</h2>
          <iframe width="100%" class="tutorial-movie" src="https://www.youtube.com/embed/KqTRkmg5YkE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <v-btn @click="tutorial_movie3=!tutorial_movie3; tutorial_movie4=!tutorial_movie4; tutorial_movie_progress+=1; completedSteps+=1">視聴完了</v-btn>
        </div>
        <div  v-if="tutorial_movie4 == true">
          <h2>動画4</h2>
          <iframe width="100%" class="tutorial-movie" src="https://www.youtube.com/embed/xrBmHkJQDx0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <v-btn @click="tutorial_movie4=!tutorial_movie4; tutorial_movie5=!tutorial_movie5; tutorial_movie_progress+=1; completedSteps+=1">視聴完了</v-btn>
        </div>
        <div  v-if="tutorial_movie5 == true">
          <h2>動画5</h2>
          <iframe width="100%" class="tutorial-movie" src="https://www.youtube.com/embed/5SEYwKLf0vg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <v-btn @click="rank='F';completedSteps=5;completedSteps=0; movie_done(); rankup_flag=true">視聴完了</v-btn>
        </div>
        
      </div>
    </div>
    <div v-show="rank=='F'">
      <h2>あなたのエッチの特徴、性癖、嗜好を教えてください！</h2>
<label><span v-if="form.propensity.replace(/\s+/g, '').length<50" style="color:red">{{form.propensity.replace(/\s+/g, '').length}}</span><span v-else>{{form.propensity.replace(/\s+/g, '').length}}</span> / 50文字以上</label>
<div class="white-card">
            <v-textarea
              v-model="form.propensity"
              required
            >
            </v-textarea>
</div>

      <v-btn @click="rank='E'; propensity_post(); rankup_flag=true">入力画面へ</v-btn>
    </div>
    <div v-show="rank=='E'">
      <h2>Twitter情報を入力し、設定を完了させましょう！</h2>
        <p v-if="message_text!=''" style="color:red;">{{message_text}}</p>
        <div class="form-group">
          <v-form ref="twitterid_form">
            <label for="form_twitterid">TwitterのID(@不要)</label>
            <v-text-field
              v-model="form.twitterid"
              id="form_twitterid"
              class="white-card"
              style="border: 1px solid #ccc; margin-left:0; padding-left:10px;"
              placeholder="Twitter ID(例:@yamada_hona)"
              :rules="[twitterid_rule]"
              required
            />
            <p v-show="twitter_already_taken">Twitter IDは登録されています。</p>
          </v-form>
        </div>

        <div class="form-group">
          <label for="form_twitter_name">Twitterの名前</label>
          <input v-model="form.twitter_name" type="text" class="form-control" id="form_twitter_name" placeholder="Twitterの名前" required>
        </div>
        <label style="color: red; font-size:1rem;"><Icon name="exclamation-triangle" scale="1"/>Twitterの設定からDMの解放をしてください</label>
        <div class="white-card">
        <v-radio-group row v-model="check" required>
          <v-radio label="DM解放設定をしました" value=""></v-radio>
        </v-radio-group>
        </div>
      <v-btn @click="twitter_data_post();">登録</v-btn>

    </div>
</div>
  </div>
</template>

<style scoped>
.tutorial-movie{
  aspect-ratio: 16 / 9;
}
.canvas{
  pointer-events: none;
  z-index: 1 !important;
}
.rankup-text {
  display: inline-block;
  background: linear-gradient(45deg, #FA8BFF 16%, #2BD2FF 57%, #2BFF88 90%);
  background: -webkit-linear-gradient(45deg, #FA8BFF 16%, #2BD2FF 57%, #2BFF88 90%);
  background-size: 300% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientEffect 2.5s infinite alternate;
}
 
@keyframes gradientEffect {
  from {background-position: left}
  to {background-position: right}
}
  .modal-page{
    position:fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background: #0000003d;
    z-index:9999;
  }
  .modal-box{
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    border-radius: 5px;
    background: #fff;
    width: 90%;
    max-width: 600px;
  }
  .matching-count{
    font-size: 5rem;
    font-weight: 900;
    color: #F74481;
    font-family: Montserrat;
  }
  .rec-count{
    font-size: 5rem;
    font-weight: 900;
    color: #6C35D9;
    font-family: Montserrat;
  }
.small-link{
  color: #4B4B4B;
  font-size:0.85rem;
}
.small-link:hover{
  color: #4B4B4B;
  font-size:0.85rem;
  text-decoration: none;
}

  h2{
    border-left: 5px solid ;

    border-image: linear-gradient(0deg, rgba(96,33,220,1) 0%, rgba(248,132,140,1) 100%);
    border-image-slice: 0 0 0 5;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 900;
    color: #4B4B4B;
    text-align: left;
    margin-top: 50px;
    border-bottom: none;
    border-right: none;
    border-top: none;
  }
  
  .left{
    text-align: left;
  }
  
  .white-card{
    border-radius: 5px;
    background: white;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  .btn-rank-request{
    background: linear-gradient(#70708c, #5b577a);
    border-radius: 320px;
    color: #ffffff;
    font-family: Lato;
    font-size: 1.2rem;
    font-weight: 900;
    height: auto;
    line-height: 1.4;
    padding: 10px 15px 10px 15px;
    text-align: center;
    width: auto;
    max-width: 100%;
    justify-content: center;
}
  .btn-recommend-request{
    /*background: linear-gradient(#70708c, #5b577a);*/
    background: #F74481;
    border-radius: 320px;
    color: #ffffff;
    font-family: Lato;
    font-size: 1.2rem;
    font-weight: 900;
    height: auto;
    line-height: 1.4;
    padding: 10px 15px 10px 15px;
    text-align: center;
    width: auto;
    max-width: 100%;
    width: 100%;
    justify-content: center;

}
  .btn-recommend-request-mannual{
    /*background: linear-gradient(#70708c, #5b577a);*/
    background: #6C41DC;
    border-radius: 320px;
    color: #ffffff;
    font-family: Lato;
    font-size: 1.2rem;
    font-weight: 900;
    height: auto;
    line-height: 1.4;
    padding: 10px 15px 10px 15px;
    text-align: center;
    width: auto;
    max-width: 100%;
    width: 100%;
    justify-content: center;
}
.limit-announce-text{
  color: #F52876;
  margin-bottom: 0;
  font-weight: 900;
  font-size: 1.1rem;
}
.btn-comp{
  color: white;
  border: none;
  border-radius: 30px;
  width: 80%;
  max-width: 300px;
  margin-top: 30px;
  padding: 12px;
  font-size: 1rem;
  font-weight: 900;
}

.login-btn{
  background: rgb(248,70,129);
  background: linear-gradient(90deg, rgba(248,70,129,1) 0%, rgba(255,150,145,1) 100%);
}

.signin-btn{
  background: rgb(97,34,219);
  background: linear-gradient(90deg, rgba(97,34,219,1) 0%, rgba(91,103,229,1) 100%);
  margin-top: 3px;
}


.v-slide-group__content {
  justify-content: center;
}
.v-slide-group:not(.v-slide-group--has-affixes) > .v-slide-group__prev,
.v-slide-group:not(.v-slide-group--has-affixes) > .v-slide-group__next {
display: inherit !important;
}
.v-slide-group__next--disabled,
.v-slide-group__prev--disabled {
pointer-events: auto !important;
}
.v-messages__message{
  color:red !important;
}

.container{
  max-width: 800px;
}
  .alertlink{
    font-size: 1rem;
  }
  .q-box{
    height: 25%;
  }
  .q-box-text{
    font-size: 0.6rem;
  }
  @media (min-width:  768px){
    .q-box{
      height: 50%;
    }
    .q-box-text{
      font-size: 1rem;
    }
  }
  .q-wrap{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  flex-flow: column;
  height:100%;
  width: 100%;
  justify-content:space-between;
  }
  .alertlink{
    font-size: 1rem;
  }
  h2{
    border-left: 5px solid ;
    border-image: linear-gradient(0deg, rgba(96,33,220,1) 0%, rgba(248,132,140,1) 100%);
    border-image-slice: 0 0 0 5;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 900;
    color: #4B4B4B;
    text-align: left;
    margin-top: 50px;
  }
  .v-input{
    margin-bottom: 0;
  }
  a{
    color: #4B4B4B;
  }
  a:hover{
    color: #4B4B4B;
  }
  .btn-comp{
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 30px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }
  
  .login-btn{
background: rgb(248,70,129);
background: linear-gradient(90deg, rgba(248,70,129,1) 0%, rgba(255,150,145,1) 100%);
  }

  .signin-btn{
background: rgb(97,34,219);
background: linear-gradient(90deg, rgba(97,34,219,1) 0%, rgba(91,103,229,1) 100%);
    margin-top: 3px;
  }

  .forgot-information-text{
    font-size: 0.8rem;
    padding-top: 20px;
  }
  .get-account-information-text{
    font-size: 0.8rem;
    margin-top: 40px;
    margin-bottom: 8px;
  }
  .v-input{
    margin-left: 15px;
  }
  .v-radio{
    margin-bottom: 10px;
  }
  .v-input__control{
    margin-right: 15px;
  }


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>


<script>
  import moment from "moment";
  import axios from "../../util/axios";
  import HalfRanktile from '@/components/HalfRankTile.vue'
  import Spinner from 'vue-simple-spinner'
  import { ParticlesBg } from "particles-bg-vue";

  export default {
    name: "Home",

  head: {
    title() {
      return {
        inner: '性癖マッチン',
        separator: '|',
        complement: '',
      }
    },
    meta: [
      { name: '性癖マッチン', content: '性癖マッチン', id: 'desc' }
    ]
  },
    data() {
      return {

        isLoading: true,
        completedSteps: 1,

        isLovecosme: false,
        isGme: false,
        isSuyatsu:false,
        isLovecosme2: false,
        isViewLovecosme: false,
        isViewGme: false,
        isViewSuyatsu:false,
        isViewLovecosme2: false,
        message:"",
        twitter_already_taken: false,
        id:"",
        uid: "",
        client: "",
        access_token: "",
        email: "",
        twitterid: "",
        info:"",
        info_ts: "",
        info_url: "",
        payment_conf: "",
        age_conf: "",
        age_conf_file: false,
        data_all: "",
        rank: "-",
        sex: "",
        rc: "",
        status:"",
        active_status:"",
        rank_req_flag: "",
        create_review:false,
        form_post:false,
        message_flag:false,
        message_text:"",
        matching_count: 0,
        recommend_count: 0,
        mannual_recommend: false,
        recommend_create_count: 0,
        age_conf_flag: this.$route.query.age_conf_flag,
        tutorial_check: false,

        tutorial_movie_progress:0,
        tutorial_movie1:true,
        tutorial_movie2:false,
        tutorial_movie3:false,
        tutorial_movie4:false,
        tutorial_movie5:false,
        
        form:{propensity:''},
        check:false,
        twitterid_rule:
        v => /^[0-9a-zA-Z_]{1,15}$/.test(v) || 'TwitterIDを入力してください。',
        config:{




        },
        rankup_flag:true,
      };
    },
    computed:{
      paymentConf: function (){
        if(this.payment_conf == 1){
          return "決済登録済"
        } else {
          return "決済が確認できていません。決済の確認・登録を行なってください。"
        }
      },
      ageConf: function (){
        if(this.age_conf == 2){
          return `年齢確認済`
        } else if( this.age_conf == 1){
          return "年齢確認書類の再提出が必要です。"
        } else if ( this.age_conf_file) {
          return "年齢確認書類確認中"
        } else {
          return "年齢確認書類の提出が必要です。"
        }
      },
      ageConfMessage: function(){
        if(this.age_conf_flag == 1){
          return `年齢確認書類が提出されました。審査完了までお待ちください。`
        } else if(this.create_review == true) {
          return "レビューを投稿しました。"
        } else if(this.form_post==true){
          return "登録情報を更新しました。"
        } else if(this.message_text != ""){
          return this.message_text
        } else {
          return ""
        }
      }
    },

    methods: {
      async movie_done(){
        const result = await axios({
        method : 'POST',
        url    : "/api/user/movie_done",
        headers: {'Content-Type': 'application/json',
                uid: this.uid,
                "access-token": this.access_token,
                client: this.client,
              },
        data: {
              user:{
                id: this.id,
              }
            }
        })
        if (!result.data.success) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          this.message = "入力内容を確認してください"
          return;
        }

      },
      async propensity_post() {
        this.form.propensity.replace(/\n/g,'\\n')
        const result = await axios({
        method : 'POST',
        url    : "/api/user/propensity_update",
        headers: {'Content-Type': 'application/json',
                uid: this.uid,
                "access-token": this.access_token,
                client: this.client,
              },
        data: {
              user:{
                id: this.id,
                maleraw_attributes:{
                  propensity: this.form.propensity,
                }
            }
            }}).catch((e) => {
              e
            });

            if (!result.data.success) {
              // エラーの場合ログイン画面へ遷移させる
              // this.redirectLogin();
              this.message = "入力内容を確認してください"
              return;
            }
            if(result.data.success) {
              this.post_content="";
              this.getAccountData();
              // this.$router.push("home");
              // this.$router.push('/maletutorial');
              this.message = "更新しました"
            }
          },
      async twitter_data_post() {
        this.twitter_already_taken= false
        if (this.$refs.twitterid_form.validate()) {
          const result = await axios({
          method : 'POST',
          url    : "/api/user/twitterid_update",
          headers: {'Content-Type': 'application/json',
                  uid: this.uid,
                  "access-token": this.access_token,
                  client: this.client,
                },
          data: {
                user:{
                  id: this.id,
                  twitterid: this.form.twitterid,
                  maleraw_attributes:{
                    twitter_name: this.form.twitter_name,
                  }
          }
          }}).catch((e) => {
            this.message = "入力内容を確認してください"
            this.twitter_already_taken = true;
            e
          });

          if (!result.data.success) {
            // エラーの場合ログイン画面へ遷移させる
            // this.redirectLogin();
            this.message = "入力内容を確認してください"
            this.twitter_already_taken = true;
            return;
          }
          if(result.data.success) {
            this.post_content="";
            this.getAccountData();
            // this.$router.push("home");
            // this.$router.push('/maletutorial');
            this.message = "更新しました"
            this.$router.push("home");
          } else {
            this.message = "入力内容を確認してください"
            this.twitter_already_taken = true;
            return;
          }
        } else {
          this.message_text = "TwitterIDの入力を確認してください"
        }
          },
        async getAccountData() {
        // const result = await axios.get("https://matching.itonami.design/api/account", {
          const result = await axios.get("/api/account", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          e;
        });
        this.twitterid = result.data.twitterid;
        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          this.redirectLogin();
          return;
        }
        if (!result.data.email) {
          // エラーの場合ログイン画面へ遷移させる
          this.redirectLogin();
          return;
        }

        const result_modal = await axios.get("/api/modal/list", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          e;
        });

        const cnt = result_modal.data.modal_list.length

        if(cnt == 0){
          this.isLovecosme2 = true
        }
        else{
          let is_love = true
          let is_love2 = true
          is_love
          is_love2
          let is_gme = true
          let is_suyatsu = true
          result_modal.data.modal_list.forEach(function(list){
            if(list.code == 1){
              is_love = false
            }
            if(list.code == 2){
              is_gme = false
            }
            if(list.code == 3){
              is_suyatsu = false
            }
            if(list.code == 4){
              is_love2 = false
            }
          })
          if(is_love2){
            this.isLovecosme2 = true
          }
          else if(is_gme){
            this.isGme = true
          }
          else if(is_suyatsu){
            this.isSuyatsu = true
          }
        }

        this.id = result.data.id
        this.email = result.data.email;
        this.twitterid = result.data.twitterid;
        this.twitter_name=result.data.twitter_name
        this.info = result.data.notification[0].info;
        this.info_ts = result.data.notification[0].created_at;
        this.info_url = result.data.notification[0].url;
        this.payment_conf= result.data.payment_conf;
        this.age_conf = result.data.age_conf; 
        this.age_conf_file = result.data.age_conf_file; 
        this.data_all = result.data;
        this.status = result.data.status;
        this.active_status = result.data.active_status;
        this.sex = result.data.sex;
        localStorage.setItem('sex', this.sex);
        this.rank = result.data.rank;
        this.rank_req_flag = result.data.rank_req_flag;
        this.matching_count = result.data.matching_count;
        this.recommend_count = result.data.recommend_count;
        this.recommend_id = result.data.recommend_id;
        this.matching_id = result.data.matching_id;
        this.suspend_id = result.data.suspend_id;
        this.action = result.data.action;
        this.recommendable = result.data.recommendable;
        this.rc = result.data.rc;
        this.recommend_create_count = result.data.recommend_create_count;
        this.mannual_recommend = result.data.mannual_recommend;
        this.tutorial_check = result.data.tutorial_check;
        if (this.matching_count==null){
          this.matching_count = 0;
        }
        if (this.recommend_count == null){
          this.recommend_count = 0;
        }
        if(this.age_conf==3 || this.age_conf==4){
          this.logout();
        }
        if(this.active_status=='inactive'){
          this.logout(); 
        }

        this.isLoading = false;
        // this.redirectForm();
        
        if(this.sex==2 && this.tutorial_check==0 && this.age_conf == 2 && this.twitter_name != null){
          this.$router.push("/tutorial");
        }
      if(!['H','G','F','E'].includes(this.rank)){
        this.$router.push('/home')
      }

        setTimeout(this.setCompletedSteps, 1000);
      },

      async submitStatusActivate(){
        this.isLoading = true;
        const result = await axios({
          method : 'POST',
          url    : "/api/female_status_activate_transaction",
          headers: {'Content-Type': 'application/json',
                  uid: this.uid,
                  "access-token": this.access_token,
                  client: this.client,
                },
          data: {
            female_status_activate_transaction:{
              status_old: this.status,
            }}
        }).catch((e) => {
          e
          this.$router.push('/login');
        });

        if (!result) {
          this.$router.push('/login');
          this.isLoading = false;
          return;
        }
        this.status = 4;
        this.isLoading = false;
      },
      redirectLogin() {
        //ページ遷移
        this.$router.push("/login");
      },
      redirectForm() {
        if(this.sex == 1) {
          if((this.twitter_name == null)||(this.twitter_name === '')) {
            this.$router.push("/partialform");
          }
          //年齢確認ページに移動
          else if(this.age_conf != 2 && !this.age_conf_file){
            this.$router.push("/age_confirmation")
          }
          //決済ページへ移動
          else if(this.payment_conf != 1 ){
            this.$router.push("/payment_information")
          }
        } else if (this.sex == 2) {
          if(this.twitter_name == null){
            this.$router.push("/femaleform");
          } else if(this.age_conf != 2 && !this.age_conf_file){
            this.$router.push("/age_confirmation")
          }
        }
      },
      async logout() {
          localStorage.removeItem('uid')
          localStorage.removeItem('client')
          localStorage.removeItem('access-token')
        // const self = this;
        const result = await axios.delete("/api/auth/sign_out",{
          // test: { test: "test" },
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          console.error(e);
        });

        if (!result) {
          this.message = "エラー";
          this.$router.push("/login");
          return;
        }
        if (!result) {
          this.message = "エラー";
          this.$router.push("/login");
          return;
        }

        if (result) {
          //結果を基にページ遷移
          localStorage.setItem('sex', 4)
          localStorage.removeItem('uid')
          localStorage.removeItem('client')
          localStorage.removeItem('access-token')
          localStorage.removeItem('sex')
          localStorage.setItem('message', '18歳未満の方はご利用いただけません。')
          this.$router.push("/login");
        }
      },
    },
    filters: {
        moment:function(date){
            return moment(date).format('YYYY/MM/DD');
        }
    },
    mounted() {
      localStorage.removeItem('message')
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.create_review = this.$route.query.create_review 
      this.form_post = this.$route.query.form_post
      this.ng_commit = this.$route.query.ng_commit
      this.getAccountData();
      // this.rankup_flag = true;
      if(!['H','G','F','E'].includes(this.rank)){
        // this.$router.push('/home')
      }
    
    },
    updated() {
      // setTimeoutで3000ms後にshowをfalseにする
      setTimeout(() => {
        this.rankup_flag = false}
        ,2000
      )
    },
    components: {
      HalfRanktile,
      // Icon,
      Spinner,
      ParticlesBg,

    }
  };
</script>