<template>
  <div class="recommends container content-wrapper" style="padding-top:10px;">
    <div v-show="isLoading" style="padding-top:60px;">
      <Spinner></Spinner>
    </div>
    <div v-show="!isLoading">
      <p v-if="warning_flag" style="color:red;">
        エラーが発生しました。しばらく経ってから再度お試しください。<br>
        {{ message }}
      </p>
      <div class="container">
        <div class="white-card" v-show="!cancel_reason_submitted">
          <div class="container">
            <h2>退会手続きを行います。今後のサービス改善の為、退会理由をお教えください。</h2>
            <v-checkbox
              v-model="cancel_reason"
              label="レコメンドされなかった"
              value="レコメンドされなかった"
            ></v-checkbox>
            <v-checkbox
              v-if="sex==1"
              v-model="cancel_reason"
              label="マッチンした女性と会うまで至らなかった"
              value="マッチンした女性と会うまで至らなかった"
            ></v-checkbox>
            <v-checkbox
              v-if="sex==2"
              v-model="cancel_reason"
              label="マッチンした男性と会うまで至らなかった"
              value="マッチンした男性と会うまで至らなかった"
            ></v-checkbox>
            <v-checkbox
              v-model="cancel_reason"
              label="月額費が高い"
              value="月額費が高い"
            ></v-checkbox>
            <v-checkbox
              v-model="cancel_reason"
              label="Twitterを使わなくなった"
              value="Twitterを使わなくなった"
            ></v-checkbox>
            <v-checkbox
              v-model="cancel_reason"
              label="その他"
              value="その他"
            ></v-checkbox>
            <v-textarea v-model="cancel_reason_text" :rules="myrules" style="margin: 10px; " v-show="cancel_reason=='その他'" label="よろしければ詳細をお聞かせください。" counter="100"></v-textarea>
    
            <v-btn v-if="submittable" class="btn-comp login-btn" @click="submit_cancel_reason()">提出</v-btn>
            <v-btn v-else class="btn-comp disabled-btn" disabled>提出</v-btn>
          </div>
        </div>
    
      <div v-show="cancel_reason_submitted && !warning_flag">
        <div style="text-align:left">
          <h2>ご回答ありがとうございました。</h2>
          <div>
            <h2>決済退会手続き{{ site_id }}{{ }}</h2>
            <div v-show="!isLoading">
              <v-btn @click="submit()">決済退会</v-btn>
            </div>
            <div v-show="isLoading">
              <Spinner />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
  .btn-comp{
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 30px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }
  
  .login-btn{
    background: rgb(248,70,129);
    background: linear-gradient(90deg, rgba(248,70,129,1) 0%, rgba(255,150,145,1) 100%);
  }
  .disabled-btn{
    background: gray;
    color: #999999;
  }
  label{
    border-left: 5px solid ;
    border-image: linear-gradient(0deg, rgba(96,33,220,1) 0%, rgba(248,132,140,1) 100%);
    border-image-slice: 0 0 0 5;
    padding-left: 10px;
    font-size: 0.8rem;
    font-weight: 900;
    color: #4B4B4B;
    text-align: left;
    margin-top: 30px;
  }
</style>

<script>
  import axios from "../../util/axios";
  import Spinner from 'vue-simple-spinner'

  export default {
    name: "PaymentCancellation",
    data() {
      return {
        myrules: [v => v.length <= 100 || '100文字以内で入力してください。'],
        isLoading: true,
        completedSteps: 0,
        cancel_reason: "",
        cancel_reason_submitted: false,
        cancel_reason_text: "",
        uid: "",
        client: "",
        access_token: "",
        email: "",
        twitterid: "",
        info:"",
        info_ts: "",
        payment_conf: "",
        payment_kind: 0,
        age_conf: "",
        data_all: "",
        rank: "-",
        sex: "",
        matching_count: 0,
        recommend_count: 0,
        site_id: "",
        site_pass: "",
        payment_id: "",
        payment_pass: "",
        warning_flag: false,
        payment_resource: "",
      };
    },
    mounted() {

      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.sex=localStorage.getItem('sex')
      this.getAccountData();
      if (this.uid===null){
        this.$router.push('/login');
      }
    },
    computed: {
      submittable: function () {
      if(this.cancel_reason != '' && this.cancel_reason != null && this.cancel_reason != 'その他')
        return true
      else if(this.cancel_reason =='その他' && this.cancel_reason_text.length < 101)
        return true
      else
        return false
      }
    },
    methods: {
      async submit_cancel_reason() {
        this.isLoading=true
        this.cancel_reason_submitted = !this.cancel_reason_submitted
        
        await axios.post("/api/cancel_reason", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
          
          cancel_reason: {
            uid: this.uid,
            cancel_reason:this.cancel_reason,
            cancel_reason_text: this.cancel_reason_text
          }

        }).catch((e) => {
          e
          this.warning_flag=true
          this.cancel_reason_submitted=false
          this.isLoading=false
          return;
        });
        this.isLoading=false
      },
      async submit() {
        this.isLoading=true;
        const result = await axios({
        method : 'POST',
        url    : "/api/al_payment/cancellation/submit",
        headers: {'Content-Type': 'application/json',
                uid: this.uid,
                "access-token": this.access_token,
                client: this.client,
              },
        data   : {
          uid: this.uid,
        }
        }).catch((e) => {
          e
          this.isLoading = !this.isLoading;
        });

        if(!result.data.success){
          this.message = "退会処理に失敗しました。時間をおいて再度行ってください。";
          this.warning_flag = true;
          this.message = result.data.msg;
          this.isLoading = !this.isLoading;
        }
        if(result.data.success) {
          this.$router.push({ name: 'Home'});
          this.message = "更新しました"
        }
      },
      async getAccountData() {
        const result = await axios.get("/api/payment_information", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          e
        });

        this.isLoading = false;
        this.payment_id = result.data.payment_id;
        this.payment_pass = result.data.payment_pass;
        this.payment_conf= result.data.payment_conf;
        this.payment_kind= result.data.payment_kind;
        this.payment_resource = result.data.payment_resource;
        if(this.payment_kind==2500){
          this.site_id = "32307805"
          this.site_pass = "6qoFpigk"
        } else if(this.payment_kind==6600){
          this.site_id = "32307806"
          this.site_pass = "Fbc5xrWe"
        } else if(this.payment_kind==12000){
          this.site_id = "32307807"
          this.site_pass = "TSRMPfZE"
        } else if(this.payment_kind==20000){
          this.site_id = "32307810"
          this.site_pass = "sSB5Qm3w"
        }
        this.email = result.data.email;
        if (this.matching_count==null){
          this.matching_count = 0;
        }
        if (this.recommend_count == null){
          this.recommend_count = 0;
        }
        if (this.payment_conf!="1"){
          this.$router.push('/home');
        }
      },
    },
    components:{
      Spinner
    }
  }
</script>