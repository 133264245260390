<template>
	<div class="PaymentSelect container" style="margin-top:50px; margin-bottom:80px;">
	  <transition name="fade">
	  <div id="modal" @click="modal_show=false" v-show="modal_show">
		<div class="container">
		  <div class="white-card" style="margin-top:200px; padding:30px;">
			<h2>{{plan_name}} 注意事項</h2>
			<div class="text-box" style="text-align: left;">
			  <p v-show="plan==1">＊初回決済日を起算日として31日後に2回目の決済、以降30日サイクルの決済となります。</p>
			  <p v-show="plan==2">＊初回決済日を起算日として91日後に2回目の決済、以降90日サイクルの決済となります。</p>
			  <p v-show="plan==3">＊初回決済日を起算日として181日後に2回目の決済、以降180日サイクルの決済となります。</p>
			  <p v-show="plan==4">＊初回決済日を起算日として361日後に2回目の決済、以降360日サイクルの決済となります。</p>
			  <p>＊決済プランの変更は次回決済日前に一度退会し、マイページ決済情報より再度ご希望のプランで決済登録の手続きが必要です。</p>
			  <p>＊退会希望の場合は退会ページより必要事項を入力し退会手続きを行ってください。決済会社より退会のメール受信で正式に退会完了となります。</p>
			  <p>＊決済の取り消しは行ないません。 <a href="https://service.seiheki-matching.jp/MEN_userpolicy" target="_blank">利用規約第10条</a>をご確認ください。</p>
			</div>
			<a :href="'https://mc-credit.com.sg/service/credit/input.html?SiteId=32307805&SitePass=6qoFpigk&Amount=2500&mail='+uid" v-if="plan==1">
			  <button class="btn-rank-request">
				決済サービスへ移動
			  </button>
			</a>
			<a :href="'https://mc-credit.com.sg/service/credit/input.html?SiteId=32307806&SitePass=Fbc5xrWe&Amount=6600&mail='+uid" v-if="plan==2">
			  <button class="btn-rank-request">
				決済サービスへ移動
			  </button>
			</a>
			<a :href="'https://mc-credit.com.sg/service/credit/input.html?SiteId=32307807&SitePass=TSRMPfZE&Amount=12000&mail='+uid" v-if="plan==3">
			  <button class="btn-rank-request">
				決済サービスへ移動
			  </button>
			</a>
			<a :href="'https://mc-credit.com.sg/service/credit/input.html?SiteId=32307810&SitePass=sSB5Qm3w&Amount=20000&mail='+uid" v-if="plan==4">
			  <button class="btn-rank-request">
				決済サービスへ移動
			  </button>
			</a>
		  </div>
		</div>
	  </div>
	  </transition>

	  <div v-if="pp=='al'">
		<div>
		  <h2>個数選択</h2>
		  <div class="row" v-if="!selected">
			<div class="col-md-12">
			  <div class="white-card" @click="plan=1; selected=!selected; payment_amount=1500">
				<p class="like-amount">10いいね</p>
				<p class="amount">¥<span class="amount-value">1,500<span style="font-size:1.2rem;">(税込)</span></span></p>
				<p>１いいね150円</p>
			  </div>
			</div>
			<div class="col-md-6">
			  <div class="white-card" @click="plan=2; selected=!selected; payment_amount=3900">
				<p class="like-amount">30いいね</p>
				<p class="amount">¥<span class="amount-value">3,900<span style="font-size:1.2rem;">(税込)</span></span></p>
				<p>１いいね130円</p>
			  </div>
			</div>
			<div class="col-md-6">
			  <div class="white-card" @click="plan=3; selected=!selected;payment_amount=5000">
				<p class="like-amount">50いいね</p>
				
				<p class="amount">¥<span class="amount-value">5,000<span style="font-size:1.2rem;">(税込)</span></span></p>
				<p>１いいね100円</p>
			  </div>
			</div>
			<div class="col-md-6">
			  <div class="white-card" @click="plan=4; selected=!selected; payment_amount=8000">
				<p class="like-amount">100いいね</p>
				<p class="amount">¥<span class="amount-value">8,000<span style="font-size:1.2rem;">(税込)</span></span></p>
				<p>１いいね80円</p>
			  </div>
			</div>
			<div class="col-md-6">
			  <div class="white-card" @click="plan=5; selected=!selected; payment_amount=50000">
				<p class="like-amount">1000いいね</p>
				<p class="amount">¥<span class="amount-value">50,000<span style="font-size:1.2rem;">(税込)</span></span></p>
				<p>１いいね50円</p>
			  </div>
			</div>
		  </div>
		</div>
		<!-- カード入力 -->
		<div v-show="selected">
		  <v-container>
			<div class="row">
			  <div class="col-md-12">
				<div class="white-card">
				  <p style="font-weight:900; font-size:1.2rem;">{{plan_name}}</p>
				  <p class="amount">¥<span class="amount-value">{{plan_amount}}</span><span style="font-size:1rem;">(税込)</span></p>
				  <p>いいね購入は24時間に1度だけ可能です。</p>
				  <p>有効期限：購入後180日間</p>
				</div>
				<v-btn @click="selected = !selected">プラン選択に戻る</v-btn>
			  </div>
			</div>
		  </v-container>
		  <v-app>
			<v-container>
			  <v-form ref="al_form" :lazy-validation="true">
				<h2>カード番号</h2>
				<v-row>
				  <v-col cols="2">
					<img src="@/assets/cards/visa.png" style="width:100%;">
				  </v-col>
				  <v-col cols="2">
					<img src="@/assets/cards/master.png" style="width:100%;">
				  </v-col>
				  <v-col cols="2">
					<img src="@/assets/cards/jcb.png" style="width:100%;">
				  </v-col>
				  <v-col cols="2">
					<img src="@/assets/cards/diners.png" style="width:100%;">
				  </v-col>
				</v-row>
				<v-text-field
				  v-model="al_form.no"
				  placeholder="XXXXXXXXXXXXXXX"
				  :rules="[required, card_rule]"
				  autocomplete="cc-number"
				  />
				<h2>カード名義</h2>
				<v-text-field
				  v-model="al_form.name"
				  placeholder="SEIHEKI CHANG"
				  :rules="[required]"
				  autocomplete="cc-name"
				  />
				<h2>有効期限</h2>
				<v-row>
				  <v-col cols="6">
					<v-select
					  v-model="al_form.expm"
					  placeholder="MM"
					  :items="months"
					  :rules="[required,mm_rule]"
					  autocomplete="cc-exp-month"
					  />
				  </v-col>
				  <v-col cols="6">
					<v-select
					  v-model="al_form.expy"
					  placeholder="YY"
					  :items="years"
					  :rules="[required,yy_rule]"
					  autocomplete="cc-exp-year"
					  />
				  </v-col>
				</v-row>
				<h2>CVV2</h2>
				<v-text-field
				  v-model="al_form.cvv2"
				  placeholder="XXX"
				  :rules="[required,cvv2_rule]"
				  autocomplete="cc-csc"
				  />
			  </v-form>
			  <div v-show="isLoading" style="padding-top:10px;">
				<Spinner></Spinner>
			  </div>
			  <div v-show="!this.isLoading">
				<p style="font-weight: 900;">{{message}}</p>
				<v-btn @click='purchase()' class="btn-comp login-btn">購入</v-btn>
				<p></p>
				
				<p>商品代金の支払い時期と商品引渡時期について</p>
				<a href="https://service.seiheki-matching.jp/MEN_tokutei" target="_blank">特定商取引法表示</a>にてご確認ください。
			  </div>
			</v-container>
		  </v-app>
		</div>
		
	  </div>
	</div>
  </template>
  
  <style scoped>
	.like-amount{
	  font-size: 1.2rem;
	  font-weight: 900;
	  text-align: center;
	  margin-top: 10px;
	  color: #4B4B4B !important;
	}
	.noti-text{
	  font-weight: 900;
	  background: linear-gradient(315deg, rgba(250, 160, 152, 0.9), rgba(248, 96, 141, 0.9) 34%, rgba(113, 129, 237, 0.9) 69%, rgba(118, 82, 230, 0.9));
	  -webkit-background-clip: text;
	  color: transparent;
	  font-size:1rem;
	}
	.noti-text-num{
	  font-size:2rem;
	}
	h2{
	  margin-top: 10px !important;
	}
	.alert{
	  color: red;
	  text-align: left;
	  margin: auto;
	  margin-left: 0;
	  top: 0;
	  bottom: 0;
	  padding-left: 0px;
	}
	.alert-icon{
	  vertical-align: middle;
	  display: flex;
	  padding: 15px;
	  margin: 0;
  
	}
	.alert-wrapper{
	  display: flex;
	  flex-direction: row;
	  vertical-align: middle;
	}
	.amount{
	  font-weight: 900;
	  font-size: 2.5rem;
	  background: linear-gradient(315deg, rgba(250, 160, 152, 0.9), rgba(248, 96, 141, 0.9) 34%, rgba(113, 129, 237, 0.9) 69%, rgba(118, 82, 230, 0.9));
	  -webkit-background-clip: text;
	  color: transparent;
	}
	.amount-value{
	  font-size: 3rem;
	}
	.amount-disabled{
	  font-weight: 900;
	  font-size: 2.5rem;
	  color: gray !important;
	}
	
	#modal{
	  width: 100%;
	  height: 100%;
	  top: 0;
	  left: 0;
	  position: absolute;
	  background: rgba(0,0,0,0.5);
	  z-index: 100;
	}
	.fade-enter-active, .fade-leave-active {
	  transition: opacity .15s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	  opacity: 0;
	}
	.btn-rank-request{
	  background: linear-gradient(#70708c, #5b577a);
	  border-radius: 320px;
	  color: #ffffff;
	  font-family: Lato;
	  font-size: 1.2rem;
	  font-weight: 900;
	  height: auto;
	  line-height: 1.4;
	  padding: 10px 15px 10px 15px;
	  text-align: center;
	  width: auto;
	  max-width: 100%;
	  justify-content: center;
  }
  
	.btn-comp{
	  color: white;
	  border: none;
	  border-radius: 30px;
	  width: 80%;
	  max-width: 300px;
	  margin-top: 30px;
	  padding: 12px;
	  font-size: 1.2rem;
	  font-weight: 900;
	}
	
	.login-btn{
  background: rgb(248,70,129);
  background: linear-gradient(90deg, rgba(248,70,129,1) 0%, rgba(255,150,145,1) 100%);
	}
  
	.signin-btn{
  background: rgb(97,34,219);
  background: linear-gradient(90deg, rgba(97,34,219,1) 0%, rgba(91,103,229,1) 100%);
	  margin-top: 3px;
	}
  
  </style>
  <script>
  import axios from "../../util/axios";
  import Spinner from 'vue-simple-spinner'
  
  export default {
  
	name: 'PaymentSelect',
	components: {
	  Spinner,
	},
	data() {
	  return{
		uid:null,
		client:null,
		access_token:null,
		plan:0,
		modal_show:false,
		pp:'al',
		site_id:'',
		al_form:{
		  no:'',
		  name:'',
		  expm:'',
		  expy:'',
		  cvv2:''
		},
		card_rule:
		  v => /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6011[0-9]{12}|3(?:0[0-5]|[68][0-9])[0-9]{11}|3[47][0-9]{13}|(?:2131|1800|35[0-9]{3})[0-9]{11})$/.test(v) || "有効なカード情報を入力してください",
		required:
		  v => !!v || "項目を入力してください",
		yy_rule:
		  v => /\d{2}/.test(v) || "有効な年を入力してください",
		mm_rule:
		  v => /^(0?[1-9]|1[0-2])$/.test(v) || "有効な月を入力してください",
		cvv2_rule:
		  v => /^[0-9]{3,4}$/.test(v) || 'CVV2コードは3桁または4桁の数字で入力してください。',
		// months:['01','02','03','04','05','06','07','08','09','10','11','12'],
		months:[1,2,3,4,5,6,7,8,9,10,11,12],
		years:[2023,2024,2025,2026,2027,2028,2029,2030,2031,2032,2033,2034],
		al_form_is_valid:false,
		token:'',
		message:'決済情報を入力してください',
		isLoading:false,
  
		payment_conf: "",
		next_payment_date: "",
		listed: false,

		selected: false,
	  }
	},
	computed:{
	  plan_name: function(){
		if(this.plan==1){
		  return "いいね 個数 10"
		}else if(this.plan==2){
		  return "いいね 個数 30"
		}else if(this.plan==3){
		  return "いいね 個数 50"
		}else if(this.plan==4){
		  return "いいね 個数 100"
		}else if(this.plan==5){
		  return "いいね 個数 1000"
		} else {
		  return ""
		}
	  },
	  plan_amount: function(){
		if(this.plan==1){
		  return "1,500"
		}else if(this.plan==2){
		  return "3,900"
		}else if(this.plan==3){
		  return "5,000"
		}else if(this.plan==4){
		  return "8,000"
		}else if(this.plan==5){
		  return "50,000"
		}else{
		  return ""
		}
	  },
	  site_id_computed: function(){
  
		if(this.plan==5||this.plan==9){
		  return "50130901"
		}else if(this.plan==6||this.plan==10){
		  return "50130902"
		}else if(this.plan==7||this.plan==11){
		  return "50130903"
		}else if(this.plan==8||this.plan==12){
		  return "50130904"
		}else{
		  return ""
		}
	  },
	  payment_cycle: function(){
		if(this.plan==5||this.plan==9){
		  return 30
		}else if(this.plan==6||this.plan==10){
		  return 90
		}else if(this.plan==7||this.plan==11){
		  return 180
		}else if(this.plan==8||this.plan==12){
		  return 360
		}else{
		  return ""
		}
	  },
	},
	methods:{
		async getAccountData() {
		  const result = await axios.get("/api/payment_information", {
			headers: {
			  uid: this.uid,
			  "access-token": this.access_token,
			  client: this.client,
			},
		  }).catch((e) => {
			e;
		  });
  
		  if (!result) {
			// エラーの場合ログイン画面へ遷移させる
			// this.redirectLogin();
			return;
		  }
		  this.payment_id = result.data.payment_id;
		  this.payment_pass = result.data.payment_pass;
		  this.payment_conf= result.data.payment_conf;
		  this.payment_kind=result.data.payment_kind;
		  this.last_payment_date=result.data.last_payment_date;
		  this.next_payment_date=result.data.next_payment_date;
		  this.course_name=result.data.course_name;
		  this.email = result.data.email;
  
		  if(this.payment_conf == 1){
		   this.$router.push("/home") 
		  }
  
		  this.isLoading = false;
		  setTimeout(this.setCompletedSteps, 1000);
	  },
	  al_validation_check(){
		if(this.$refs["al_form"].validate()){
		  this.al_form_is_valid=true
		} else {
		  this.al_form_is_valid=false
		}
	  },
  
	  purchase() {
		this.isLoading = !this.isLoading;
		this.site_id = "50130905"
		console.log(this.al_form.no, this.al_form.name, this.al_form.expm, this.al_form.expy, this.al_form.cvv2)
		window.TokenPay.init(this.site_id);
		window.TokenPay.createToken({
		  number: this.al_form.no, //カード番号
		  name: this.al_form.name, //カード名義
		  expMonth: this.al_form.expm, //カード有効期限(月)
		  expYear: this.al_form.expy, //カード有効期限(年)
		  cvv2: this.al_form.cvv2 //カードCVV2
		}, this.callback); //コールバック関数(トークン取得後に実行)
	  },
	  async test_purchase() {
		await axios({
		method : 'POST',
		url    : "/api/test-purchase",
		headers: {'Content-Type': 'application/json',
				uid: this.uid,
				"access-token": this.access_token,
				client: this.client,
			  },
		data   : { 
				plan: this.plan,
				pi:{
					token:this.token,
					site_id:this.site_id,
					campaign:this.listed,
					payment_amount:this.payment_amount,
				}
			  }
			})

		this.$router.push({ name: 'timeline', query: { purchased: true}});
	  },
  
	  callback(result) {
		if (result.token != null) {
		  console.log(result);
		  this.token = result.token;
		  this.post()
  
		} else {
			//エラー処理
			this.isLoading = !this.isLoading;
			alert('カード情報を正しく入力してください');
		}
	  },
	  async post() {
		const result = await axios({
		method : 'POST',
		url    : "/api/test-purchase",
		headers: {'Content-Type': 'application/json',
				uid: this.uid,
				"access-token": this.access_token,
				client: this.client,
			  },
		data   : { 
			  plan: this.plan,
			  pi:{
				token:this.token,
				site_id:this.site_id,
				campaign:this.listed,
				payment_amount:this.payment_amount,
			  }
		  }
		}).catch((e) => {
		  e
		  this.isLoading = !this.isLoading;
		});
  
		if(!result.data.success){
		  this.message = "入力内容を確認してください"
		  this.isLoading = !this.isLoading;
		}
		if(result.data.success) {
		  this.post_content="";
		  this.al_form.no = '';
		  this.al_form.name = '';
		  this.al_form.expm = '';
		  this.al_form.expy = '';
		  this.al_form.cvv2 = '';
		  // this.getAccountData();
		  this.$router.push({ name: 'timeline', query: { purchased: true}});
		  this.message = "更新しました"
		}
	  }
	},
	mounted() {
	  this.uid = localStorage.getItem('uid')
	  this.client = localStorage.getItem('client')
	  this.access_token = localStorage.getItem('access-token')
	//   this.getAccountData();
	  
	  if (this.uid===null){
		this.$router.push('/login');
	  }
	},
	unmounted() {
	  this.al_form.no = '';
	  this.al_form.name = '';
	  this.al_form.expm = '';
	  this.al_form.expy = '';
	  this.al_form.cvv2 = '';
	}
  }
  </script>