<template>
  <div class="Topics container"  style="margin-top:80px; margin-bottom:80px;">
    <div class="row">
    <div class="col-4">
      <button class="topic-sex-btn" @click="switch_com()" v-bind:class="{active: com_flag}">男女共通</button>
    </div>
    <div class="col-4">
      <button class="topic-sex-btn-male" @click="switch_male()" v-bind:class="{active_male: male_flag}">男性専用<span class="topic-sex-btn-subj-male" v-if="sex == 2" v-bind:class="{activesubj: male_flag}"><br>（女性は閲覧のみ）</span></button>
    </div>
    <div class="col-4">
      <button class="topic-sex-btn-female" @click="switch_female()" v-bind:class="{active_female: female_flag}">女性専用<span class="topic-sex-btn-subj-female" v-if="sex == 1" v-bind:class="{activesubj: female_flag}"><br>（男性は閲覧のみ）</span></button>
      
    </div>
    </div>
    <div v-show="com_flag">
      <div class="white-card topic-card" v-for="tp in com_topics" :key="tp.created_at">
        <div class="left-area">
          <Icon name="user" scale="2.5" style="color:white" class="icon-common"/>
        </div>
        <div class="mid-area">
        <router-link :to="{ name: 'Topic', query: { topicid: tp.id }}">
          <p>{{tp.created_at | moment}}</p>
          <p>{{tp.name}}</p>
        </router-link>
        </div>
        <div class="right-area">

          <p class="post-count">
            {{tp.post_count}}
          </p>

        </div>
      </div>
    </div>
    <div v-show="female_flag">
      <div class="white-card topic-card" v-for="tp in female_topics" :key="tp.created_at">
        <div class="left-area">
          <Icon name="user" scale="2.5" style="color:white" class="icon-female"/>
        </div>
        <div class="mid-area">
        <router-link :to="{ name: 'Topic', query: { topicid: tp.id }}">
          <p>{{tp.created_at | moment}}</p>
          <p>{{tp.name}}</p>
        </router-link>
        </div>
        <div class="right-area">

          <p class="post-count">
            {{tp.post_count}}
          </p>

        </div>
      </div>

    </div>
    <div v-show="male_flag">
      <div class="white-card topic-card" v-for="tp in male_topics" :key="tp.created_at">
        <div class="left-area">
          <Icon name="user" scale="2.5" style="color:white" class="icon-male"/>
        </div>
        <div class="mid-area">
        <router-link :to="{ name: 'Topic', query: { topicid: tp.id }}">
          <p>{{tp.created_at | moment}}</p>
          <p>{{tp.name}}</p>
        </router-link>
        </div>
        <div class="right-area">

          <p class="post-count">
            {{tp.post_count}}
          </p>

        </div>
      </div>

    </div>
  </div>
</template>

<style scoped="">
.active{
  background: #775DDF !important;
  color: white;
}
.active_male{
  background: #0054DF !important;
  color: white !important;
}
.active_female{
  background: #B81C95 !important;
  color: white !important; 
}

.topic-card{
  text-align: left;
  margin-top: 10px;
 display: flex;
  flex-direction: row;
  /*justify-content: space-around;*/
box-shadow: 2px 2px 5px rgb(0 0 0 / 20%);
vertical-align: middle;
position: relative;
}
.left-area{
  width: calc(30px);
  flex-grow: 1;
  vertical-align: middle;
  justify-content: center;
  display: flex;
  align-items: center;

}
.mid-area{
  flex-grow: 7;
  max-width: 80%;
}
.right-area{
  width: calc(70px);
  flex-grow: 1;
  vertical-align: middle;
  justify-content: center;
  display: flex;
  align-items: center;

}
.topic-sex-btn{
  background: white;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #775DDF;
  font-weight: 800;
  color: #775DDF;
  position: relative;
}
.topic-sex-btn-male{
  background: white;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #0054DF;
  font-weight: 800;
  color: #0054DF;
  position: relative;
}
.topic-sex-btn-female{
  background: white;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #B81C95;
  font-weight: 800;
  color: #B81C95;
  position: relative;
}
.topic-sex-btn-subj{
  font-size: 0.6rem;
  position: absolute;
  margin: auto;
  font-weight: 400;
  color: #775DDF;
  bottom: 5px;
  left: 0;
  right: 0;

}
.topic-sex-btn-subj-male{
  font-size: 0.6rem;
  position: absolute;
  margin: auto;
  font-weight: 400;
  color: #0054DF;
  bottom: 5px;
  left: 0;
  right: 0;

}
.topic-sex-btn-subj-female{
  font-size: 0.6rem;
  position: absolute;
  margin: auto;
  font-weight: 400;
  color: #B81C95;
  bottom: 5px;
  left: 0;
  right: 0;
}

.post-count{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 1px 30px;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
  /*height: 100%;*/

}

.gray {
-webkit-filter: grayscale(100%);
-moz-filter: grayscale(100%);
-ms-filter: grayscale(100%);
-o-filter: grayscale(100%);
filter: grayscale(100%);
}
.active{
  background: #775DDF !important;
  color: white;
}
.activesubj{
  color: white !important;
}
.ping{
  position: absolute;
  top: -15px;
  right: -10px;
  width: 50px;
  color: white;
}
.icon-male{
  background: #5668E8;
  padding: 8px 3px;
  border-radius: 3px;
  width: 30px;
}
.icon-female{
  background: #C240A2;
  padding: 8px 3px;
  border-radius: 3px;
  width: 30px;
}
.icon-common{
  background: #775DDF;
  padding: 8px 3px;
  border-radius: 3px;
  width: 30px;  
}
</style>

<script>
  import 'vue-awesome/icons'
  import Icon from 'vue-awesome/components/Icon'
import axios from "../../util/axios";
import moment from "moment";

export default {

  name: 'Topics',
  components:{
    Icon
  },
    filters: {
        moment:function(date){
            return moment(date).format('YYYY/MM/DD');
        }
    },
  data: function () {
    return {
        uid: "",
        client: "",
        access_token: "",
        all_data: "",
        sex: "",
        com_flag: true,
        female_flag: false,
        male_flag: false,
        com_topics:[],
        female_topics:[],
        male_topics:[],
        target:'',
      }
  },
    mounted() {
      this.target= this.$route.query.target
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.getAccountData()
      if(this.target == 'male'){
        this.switch_male()
      } else if(this.target == 'female'){
        this.switch_female()
      }
      if (this.uid===null){
        this.$router.push('/login');
      }
      
  },
    methods: {
      switch_com() {
        this.com_flag=true;
        this.female_flag=false;
        this.male_flag=false;
      },
      switch_male() {
        this.com_flag=false;
        this.female_flag=false;
        this.male_flag=true;
      },
      switch_female() {
        this.com_flag=false;
        this.female_flag=true;
        this.male_flag=false;
      },
      async getAccountData() {

        const result = await axios.get("/api/topics/index", {
        // const result = await axios.get("/api/topics/index", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          console.error(e);
        });

        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          return;
        }

        this.all_data = result.data;
        this.sex = result.data.sex;
        this.male_topics = result.data.male_data;
        this.com_topics = result.data.com_data;
        this.female_topics = result.data.female_data;
        
        // this.twitterid = result.data.twitterid;
      }
    }
}
</script>