<template>
  <div class="login" style="margin-top:50px;">
    <div class="container">
      <h2>パスワード再設定</h2>
      <div>{{ this.message }}</div>
      <input type="text" v-model="email" placeholder="メールアドレスを入力してください" /><br />
    <div v-show="isLoading" style="padding-top:60px;">
      <Spinner></Spinner>
    </div>
      <button class="login-btn btn-comp" v-on:click="post()">確認リンクを送信</button>
      <br>
    </div>
  </div>
</template>

<style scoped>
  input{
    margin-top: 30px;
    padding: 8px;
    width: 80%;
    background-color: white;
    border: none;
    border-radius: 5px;
  }

  a{
    color: #4B4B4B;
  }
  a:hover{
    color: #4B4B4B;
  }
  .btn-comp{
    color: white;
    border: none;
    border-radius: 30px;
    width: 80%;
    max-width: 300px;
    margin-top: 30px;
    padding: 12px;
    font-size: 1.2rem;
    font-weight: 900;
  }
  
  .login-btn{
background: rgb(248,70,129);
background: linear-gradient(90deg, rgba(248,70,129,1) 0%, rgba(255,150,145,1) 100%);
  }

  .signin-btn{
background: rgb(97,34,219);
background: linear-gradient(90deg, rgba(97,34,219,1) 0%, rgba(91,103,229,1) 100%);
    margin-top: 3px;
  }

  .forgot-information-text{
    font-size: 0.8rem;
    padding-top: 20px;
  }
  .get-account-information-text{
    font-size: 0.8rem;
    margin-top: 40px;
    margin-bottom: 8px;
  }
</style>

<script>
  import Spinner from 'vue-simple-spinner'
  import axios from "../../util/axios";
  const qs = require("qs");

  export default {
    name: "logIn",
    data: function () {
      return {
        isLoading: false,
        email: "",
        password: "",
        message: "",
      };
    },
    components:{
      Spinner
    },
    methods: {
      async post() {
        // const self = this;
        this.isLoading=true;
        const result = await axios
          .post("/api/auth/password", {

              email: this.email,
              redirect_url: process.env.VUE_APP_BASE_API_URL+"/password_reset",
            
            paramsSerializer: function (params) {
              return qs.stringify(params, { arrayFormat: "brackets" });
            },
          })
          .catch((e) => {
            console.error(e);
          });

        if (!result) {
          this.message = "エラーが発生しました。";
          this.isLoading=false;
          return;
        }
        if (!result.data) {
          this.message = "エラーが発生しました。";
          this.isLoading=false;
          return;
        }

        if (result.data) {
          //結果を基にページ遷移
          this.isLoading=false;
          localStorage.setItem(
            "access-token",
            result.headers["access-token"]
          );
          localStorage.setItem("client", result.headers["client"]);
          localStorage.setItem("uid", result.headers["uid"]);
          this.message="メールアドレス宛に再設定用のリンクを送信しました。"
        }
      },
    },
  };
</script>