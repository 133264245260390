<template>
  <div class="Review container" style="margin-top:80px; margin-bottom:80px;">
    <p></p>

    <div class="row">
      <div class="col-2"></div>
      <div class="col-4">
        <button class="topic-sex-btn" @click="getAccountData()" v-bind:class="{active: new_flag}">新着順</button>
      </div>
      <div class="col-4">
        <button class="topic-sex-btn" @click="getAccountDatax()" v-bind:class="{active: popular_flag}">人気順</button>
      </div>
      <div class="col-2"></div>
    </div>
    <div class="white-card" style="margin-top:5px; margin-bottom:5px; padding:10px;">
      <p>全ての女性が投稿したレビューを見ることができます</p>
    </div>

  <transition>
  <div>
 <paginate name="paginate-log" :list="reviews" :per="10">
    <div class="white-card" style="margin-top:10px;" v-for="rev in paginated('paginate-log')" :key="rev.id" >
      <div class="card-wrapper" :ref="rev.id">
      <div class="comment-area">
        <div class="point-wrapper" >
          <p class="point" :id="'post'+rev.id">満足度</p>
          <SvgProgressBar type="rect" :value="rev.point" :options="options"></SvgProgressBar>
        </div>
      <p class="comment">{{rev | text_fix}}</p>
      </div>
       <transition fade>
      <div class="detail-area" v-if="rev.detail_show">
        <div class="detail-area-wrapper">
        <p class="subj">DM対応
          <Icon :name="rev.form_dm.score|star_one" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_dm.score|star_two" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_dm.score|star_three" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_dm.score|star_four" scale="1" style="color:rgba(255,206,97,1)" />
        </p>
        <p class="detail-text">{{rev.form_dm.text|sub_text}}</p>
        <p class="subj">第一印象
          <Icon :name="rev.form_atomosphere.score|star_one" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_atomosphere.score|star_two" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_atomosphere.score|star_three" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_atomosphere.score|star_four" scale="1" style="color:rgba(255,206,97,1)" />
        </p>
        <p class="detail-text">{{rev.form_atomosphere.text|sub_text}}</p>
        <p class="subj">フォームと実際のギャップ
          <Icon :name="rev.form_profile.score|star_one" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_profile.score|star_two" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_profile.score|star_three" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_profile.score|star_four" scale="1" style="color:rgba(255,206,97,1)" />
        </p>
        <p class="detail-text">{{rev.form_profile.text|sub_text}}</p>
        <p class="subj">舌技
          <Icon :name="rev.form_tongue.score|star_one" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_tongue.score|star_two" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_tongue.score|star_three" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_tongue.score|star_four" scale="1" style="color:rgba(255,206,97,1)" />
        </p>
        <p class="detail-text">{{rev.form_tongue.text|sub_text}}</p>
        <p class="subj">手技
          <Icon :name="rev.form_hand.score|star_one" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_hand.score|star_two" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_hand.score|star_three" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_hand.score|star_four" scale="1" style="color:rgba(255,206,97,1)" />
        </p>
        <p class="detail-text">{{rev.form_hand.text|sub_text}}</p>
        <p class="subj">腰使い
          <Icon :name="rev.form_waist.score|star_one" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_waist.score|star_two" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_waist.score|star_three" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_waist.score|star_four" scale="1" style="color:rgba(255,206,97,1)" />
        </p>
        <p class="detail-text">{{rev.form_waist.text|sub_text}}</p>
        <p class="subj">後戯
          <Icon :name="rev.form_afterplay.score|star_one" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_afterplay.score|star_two" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_afterplay.score|star_three" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_afterplay.score|star_four" scale="1" style="color:rgba(255,206,97,1)" />
        </p>
        <p class="detail-text">{{rev.form_afterplay.text|sub_text}}</p>
        <p class="subj">前戯時間
          <Icon :name="rev.form_foreplay_duration.score|star_one" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_foreplay_duration.score|star_two" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_foreplay_duration.score|star_three" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_foreplay_duration.score|star_four" scale="1" style="color:rgba(255,206,97,1)" />
        </p>
        <p class="detail-text">{{rev.form_foreplay_duration.text|sub_text}}</p>
        <p class="subj">挿入時間
          <Icon :name="rev.form_sex_duration.score|star_one" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_sex_duration.score|star_two" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_sex_duration.score|star_three" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_sex_duration.score|star_four" scale="1" style="color:rgba(255,206,97,1)" />
        </p>
        <p class="detail-text">{{rev.form_sex_duration.text|sub_text}}</p>
        <p class="subj">後戯時間
          <Icon :name="rev.form_afterplay_duration.score|star_one" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_afterplay_duration.score|star_two" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_afterplay_duration.score|star_three" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_afterplay_duration.score|star_four" scale="1" style="color:rgba(255,206,97,1)" />
        </p>
        <p class="detail-text">{{rev.form_afterplay_duration.text|sub_text}}</p>
        <p class="subj">また会いたい？
          <Icon :name="rev.form_replay.score|star_one" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_replay.score|star_two" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_replay.score|star_three" scale="1" style="color:rgba(255,206,97,1)" />
          <Icon :name="rev.form_replay.score|star_four" scale="1" style="color:rgba(255,206,97,1)" />
        </p>
        <p class="detail-text">{{rev.form_replay.text|sub_text}}</p>
        
      </div>
      </div>
    </transition>
      <div class="bottom-area">
        <div class="good-area">
          <p  v-show="!rev.already_liked"><button @click="create_like(rev)"><Icon name="thumbs-up" scale="1" style="color:gray" class=""/></button>
          {{rev.liked_count}}</p>
          <p v-show="rev.already_liked"><button @click="delete_like(rev)"><Icon name="thumbs-up" scale="1" style="color:pink" class=""/></button>
          {{rev.liked_count}}</p>


        </div>
        <div class="btn-area">
        <button  v-scroll-to="{ el: '#post'+rev.id, offset: -80 }" @click="toggle(rev)" class="toggle-button"><Icon name="caret-down" scale="1" style="color:gray" class=""/></button>
        </div>
        <div class="ts-area">
          <p>{{rev.updated_at | moment}}</p>
        </div>
      </div>
    </div>
    </div>
  </paginate>
  <paginate-links   
  :limit=3
  :classes="{
    'ul': 'pagination',
    'li': 'page-item',
    'a': 'page-link',

    '.next > a': 'next-link',
    '.prev > a': ['prev-link', 'another-class'] // multiple classes
  }"
  for="paginate-log" class="pagination" :show-step-links="true"></paginate-links>
    </div>
  </transition>
  </div>
</template>
<style scoped>
p {
  margin: 0;
}
  .subj{
    padding-left: 10px;
    font-size: 0.8rem;
    font-weight: 900;
    color: #4B4B4B;
    text-align: left;
    margin-top: 10px;

  }

.point-wrapper{
  display: flex;
  flex-direction: row;
}
.card-wrapper{
  padding: 10px 10px 0 10px;
}
.active{
  background: #775DDF !important;
  color: white !important;
}
.topic-sex-btn{
  background: white;
  padding: 15px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #775DDF;
  font-weight: 800;
  color: #775DDF;
  position: relative;
}
.subj{
  padding: 3px;
  font-weight: 800;
}
.detail-text{
  padding: 3px;
}
.v-enter-active, .v-leave-active {
  transition: opacity .5s;
}
.good-area{
  padding: 5px;
}
.comment-area{
  text-align: left;
  padding: 5px;
  border-bottom: 1px dotted #d3d3d3;
}
.detail-area{
  text-align: left;
  margin-left: 10px;
}
.point{
  align-self: center;
  margin: 0;
  padding: 5px 5px 5px 0;
}
.comment{
  margin-bottom: 0;
}
.ts-area{
  vertical-align: middle;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 3px;
  font-size: 0.7rem;
}
.bottom-area {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;

  -webkit-box-pack:justify;
  -ms-flex-pack:justify;
  justify-content:space-between;
}
.toggle-button {
    align-self: center;
    border: 1px solid gray;
    height: 10px;
    padding: 3px;
    width: 50px;
    height: 30px;
    margin: 3px;
    border-radius: 3px;
}

.pagination {
  text-align: center;
  margin: 10 0 0 0;
  padding: 0;
  list-style-type: none;
  justify-content: center;
}
.pagination .number {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 48px;
  margin: 0 2px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 5px;
}
.pagination .number.active {
  background: #F5811F;
}
.pagination .number.active a {
  color: #fff;
}
.pagination .number a {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-decoration: none;
}
.pagination .number a:focus {
  outline: none;
}
</style>
<script>
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import moment from "moment";
import axios from "../../util/axios";
// import VueScrollTo from 'vue-scrollto'
import SvgProgressBar from 'svg-progress-bar'

export default {

  name: 'Review',
    data() {
      return {
        reviews:[],
        new_flag: true,
        popular_flag: false,

        paginate: ['paginate-log']
      }
    },
  mounted(){
      this.uid = localStorage.getItem('uid')
      this.client = localStorage.getItem('client')
      this.access_token = localStorage.getItem('access-token')
      this.getAccountData();
      if (this.uid===null){
        this.$router.push('/login');
      }
  },
  components:{
    Icon,
    SvgProgressBar,
    // VueScrollTo
  },
  computed: {
          options: function () {
            return {
            gradientColor:['#755AE7','#FA9E98'],
            text: function (value) {
               return '<span style="font-size: 0.4em;padding-left:30px;margin-right:-100%;">'+ this.htmlifyNumber(value) + '%</span>';
              },

            }
          }
        },
    filters: {
        moment:function(date){
          return moment(date).format('YYYY/MM/DD');
        },
        text_fix:function(rev){
          if(rev.published_text!=null){
            if(rev.detail_show == true){
              return rev.published_text
              // return 'a'
            } else {
              var slicetext = rev.published_text.length > 100 ? (rev.published_text).slice(0,100)+"…" : rev.published_text;
              console.log()
              return slicetext
              // return 'b'
            }
          } else {
            return ""
          }
        },
        sub_text:function(t){
          return t.substr(2)
        },
        star_one:function(score){
          if(score >= 1){
            return "star"
          }else{
            return "regular/star"
          }
        },
        star_two:function(score){
          if(score >= 2){
            return "star"
          }else{
            return "regular/star"
          }
        },
        star_three:function(score){
          if(score >= 3){
            return "star"
          }else{
            return "regular/star"
          }
        },
        star_four:function(score){
          if(score >= 4){
            return "star"
          }else{
            return "regular/star"
          }
        },
      },
    methods: {
      async create_like(rev){
        const result = await axios.post("/api/recommend_likes", {
        // const result = await axios.post("/api/recommend_likes", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
          uid: this.uid,
          recommend_id: rev.id,
          category: rev.category
        }).catch((e) => {
          console.error(e);
        });
        rev.liked_count+=1;
        rev.already_liked = true;
        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          return;
        }
      },
      async delete_like(rev){
        const result = await axios.delete("/api/recommend_likes", {
        // const result = await axios.delete("/api/recommend_likes", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
          params:{
          uid: this.uid,
          recommend_id: rev.id,
          category: rev.category,
          }
        }).catch((e) => {
          console.error(e);
        });
        rev.liked_count-=1;
        rev.already_liked = false;
        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          return;
        }
      },
      toggle(rev){
        if (rev.detail_show==false) {
          rev.detail_show=true
        } else {
          rev.detail_show=false
          // const dom = this.$refs[rev.id];
          // const rect = dom.getBoundingClientRect();
        }
      },
      async getAccountData() {
        const result = await axios.get("/api/public_review", {

          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          console.error(e);
        });

        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          return;
        }
        this.reviews=result.data
        this.new_flag=true
        this.popular_flag=false
        },
      async getAccountDatax() {
        const result = await axios.get("/api/public_review?order_by=popular", {
        // const result = await axios.get("/api/public_review?order_by=popular", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        }).catch((e) => {
          console.error(e);
        });

        if (!result) {
          // エラーの場合ログイン画面へ遷移させる
          // this.redirectLogin();
          return;
        }
        this.reviews=result.data
        this.new_flag=false
        this.popular_flag=true

      },
      },

  }



</script>