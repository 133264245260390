<template>
	<div class="PaymentSelect container" style="margin-top:80px;">
	  <div v-if="pp=='al'">
		<div v-show="this.sex==2 && ![13].includes(plan)">
		  <h2>プラン選択</h2>
		  <div class="row" v-if="!listed">
			<div class="col-md-12">
			  <div class="white-card" @click="setPlan(13);">
				<p>1ヶ月プレミアムプラン</p>
				<p class="amount">¥<span class="amount-value">1,100</span></p>
			  </div>
			</div>
		  </div>
		</div>
		<div v-show="![5,6,7,8,9,10,11,12].includes(plan) && this.sex==1">
		  <h2>プラン選択</h2>
		  <div class="row">
			<div class="col-md-12">
			  <div class="white-card" @click="setPlan(9);">
				<p>1ヶ月プラン</p>
				<p class="amount">¥<span class="amount-value">2,500</span></p>
			  </div>
			</div>
			<div class="col-md-6">
			  <div class="white-card" @click="setPlan(10);">
				<p>3ヶ月プラン</p>
				<p class="amount">¥<span class="amount-value">6,600</span></p>
				<p>１ヶ月あたり2,200円</p>
			  </div>
			</div>
			<div class="col-md-6">
			  <div class="white-card" @click="setPlan(11);">
				<p>6ヶ月プラン</p>
				
				<p class="amount">¥<span class="amount-value">12,000</span></p>
				<p>１ヶ月あたり2,000円</p>
			  </div>
			</div>
			<div class="col-md-6">
			  <div class="white-card" @click="setPlan(12);">
				<p>12ヶ月プラン</p>
				<p class="amount">¥<span class="amount-value">20,000</span></p>
				<p>１ヶ月あたり1,667円</p>
			  </div>
			</div>
		  </div>
		</div>
		<!-- カード入力 -->
		<div v-show="[5,6,7,8,9,10,11,12,13].includes(plan)">
		  <v-container>
			<div class="row">
			  <div class="col-md-12">
				<div class="white-card">
				  <p>{{plan_name}}</p>
				  <p class="amount">¥<span class="amount-value">{{plan_amount}}</span><span style="font-size:1rem;">(税込)</span></p>
				</div>
				<v-btn @click="plan = ''">プラン選択に戻る</v-btn>
			  </div>
			</div>
		  </v-container>
		  <v-app>
			<v-container>
			  <v-form ref="al_form" :lazy-validation="true">
				<h2>カード番号</h2>
				<v-row>
				  <v-col cols="2">
					<img src="@/assets/cards/visa.png" style="width:100%;">
				  </v-col>
				  <v-col cols="2">
					<img src="@/assets/cards/master.png" style="width:100%;">
				  </v-col>
				  <v-col cols="2">
					<img src="@/assets/cards/jcb.png" style="width:100%;">
				  </v-col>
				  <v-col cols="2">
					<img src="@/assets/cards/diners.png" style="width:100%;">
				  </v-col>
				</v-row>
				<v-text-field
				  v-model="al_form.no"
				  placeholder="XXXXXXXXXXXXXXX"
				  :rules="[required, card_rule]"
				  autocomplete="cc-number"
				  />
				<h2>カード名義</h2>
				<v-text-field
				  v-model="al_form.name"
				  placeholder="SEIHEKI CHANG"
				  :rules="[required]"
				  autocomplete="cc-name"
				  />
				<h2>有効期限</h2>
				<v-row>
				  <v-col cols="6">
					<v-select
					  v-model="al_form.expm"
					  placeholder="MM"
					  :items="months"
					  :rules="[required,mm_rule]"
					  autocomplete="cc-exp-month"
					  />
				  </v-col>
				  <v-col cols="6">
					<v-select
					  v-model="al_form.expy"
					  placeholder="YY"
					  :items="years"
					  :rules="[required,yy_rule]"
					  autocomplete="cc-exp-year"
					  />
				  </v-col>
				</v-row>
				<h2>CVV2</h2>
				<v-text-field
				  v-model="al_form.cvv2"
				  placeholder="XXX"
				  :rules="[required,cvv2_rule]"
				  autocomplete="cc-csc"
				  />
			  </v-form>
			  <div v-show="isLoading" style="padding-top:10px;">
				<Spinner></Spinner>
			  </div>
			  <div style="font-weight:700;font-size:0.7rem; text-align:left;" class="container">
				<ul>
				  <li>初回決済日を起算日として{{payment_cycle+1}}日後に2回目の決済、以降{{payment_cycle}}日サイクルの決済となります。</li>
				
				  <li>＊退会希望の場合は退会ページより必要事項を入力し退会手続きを行ってください。決済会社より退会のメール受信で正式に退会完了となります。</li>
				  <li>＊決済の取り消しは行ないません。 <a href="https://service.seiheki-matching.jp/MEN_userpolicy" target="_blank">利用規約第10条</a>をご確認ください。</li>
				</ul>
			  </div>
			  <div v-show="!this.isLoading">
				<p style="font-weight: 900;">{{message}}</p>
				<v-btn @click='purchase()' class="btn-comp login-btn">決済登録</v-btn>
			  </div>
			</v-container>
			
		  </v-app>
		</div>
		
	  </div>
	</div>
  </template>
  
  <style scoped>
	.noti-text{
	  font-weight: 900;
	  background: linear-gradient(315deg, rgba(250, 160, 152, 0.9), rgba(248, 96, 141, 0.9) 34%, rgba(113, 129, 237, 0.9) 69%, rgba(118, 82, 230, 0.9));
	  -webkit-background-clip: text;
	  color: transparent;
	  font-size:1rem;
	}
	.noti-text-num{
	  font-size:2rem;
	}
	h2{
	  margin-top: 10px !important;
	}
	.alert{
	  color: red;
	  text-align: left;
	  margin: auto;
	  margin-left: 0;
	  top: 0;
	  bottom: 0;
	  padding-left: 0px;
	}
	.alert-icon{
	  vertical-align: middle;
	  display: flex;
	  padding: 15px;
	  margin: 0;
  
	}
	.alert-wrapper{
	  display: flex;
	  flex-direction: row;
	  vertical-align: middle;
	}
	.amount{
	  font-weight: 900;
	  font-size: 2.5rem;
	  background: linear-gradient(315deg, rgba(250, 160, 152, 0.9), rgba(248, 96, 141, 0.9) 34%, rgba(113, 129, 237, 0.9) 69%, rgba(118, 82, 230, 0.9));
	  -webkit-background-clip: text;
	  color: transparent;
	}
	.amount-value{
	  font-size: 3rem;
	}
	.amount-disabled{
	  font-weight: 900;
	  font-size: 2.5rem;
	  color: gray !important;
	}
	
	#modal{
	  width: 100%;
	  height: 100%;
	  top: 0;
	  left: 0;
	  position: absolute;
	  background: rgba(0,0,0,0.5);
	  z-index: 100;
	}
	.fade-enter-active, .fade-leave-active {
	  transition: opacity .15s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	  opacity: 0;
	}
	.btn-rank-request{
	  background: linear-gradient(#70708c, #5b577a);
	  border-radius: 320px;
	  color: #ffffff;
	  font-family: Lato;
	  font-size: 1.2rem;
	  font-weight: 900;
	  height: auto;
	  line-height: 1.4;
	  padding: 10px 15px 10px 15px;
	  text-align: center;
	  width: auto;
	  max-width: 100%;
	  justify-content: center;
  }
  
	.btn-comp{
	  color: white;
	  border: none;
	  border-radius: 30px;
	  width: 80%;
	  max-width: 300px;
	  margin-top: 30px;
	  padding: 12px;
	  font-size: 1.2rem;
	  font-weight: 900;
	}
	
	.login-btn{
  background: rgb(248,70,129);
  background: linear-gradient(90deg, rgba(248,70,129,1) 0%, rgba(255,150,145,1) 100%);
	}
  
	.signin-btn{
  background: rgb(97,34,219);
  background: linear-gradient(90deg, rgba(97,34,219,1) 0%, rgba(91,103,229,1) 100%);
	  margin-top: 3px;
	}
  
  </style>
  <script>
  import axios from "../../util/axios";
  import Spinner from 'vue-simple-spinner'
  
  export default {
  
	name: 'PaymentSelect',
	components: {
	  Spinner,
	},
	data() {
	  return{
		uid:null,
		client:null,
		access_token:null,
		plan:0,
		sex:null,
		modal_show:false,
		pp:'al',
		site_id:'',
		amount:0,
		al_form:{
		  no:'',
		  name:'',
		  expm:'',
		  expy:'',
		  cvv2:''
		},
		card_rule:
		  v => /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6011[0-9]{12}|3(?:0[0-5]|[68][0-9])[0-9]{11}|3[47][0-9]{13}|(?:2131|1800|35[0-9]{3})[0-9]{11})$/.test(v) || "有効なカード情報を入力してください",
		required:
		  v => !!v || "項目を入力してください",
		yy_rule:
		  v => /\d{2}/.test(v) || "有効な年を入力してください",
		mm_rule:
		  v => /^(0?[1-9]|1[0-2])$/.test(v) || "有効な月を入力してください",
		cvv2_rule:
		  v => /^[0-9]{3,4}$/.test(v) || 'CVV2コードは3桁または4桁の数字で入力してください。',
		// months:['01','02','03','04','05','06','07','08','09','10','11','12'],
		months:[1,2,3,4,5,6,7,8,9,10,11,12],
		years:[2023,2024,2025,2026,2027,2028,2029,2030,2031,2032,2033,2034],
		al_form_is_valid:false,
		token:'',
		message:'決済情報を入力してください',
		isLoading:false,
  
		payment_conf: "",
		next_payment_date: "",
		listed: false,
	  }
	},
	computed:{
	  plan_name: function(){
		if(this.plan==1){
		  return "1ヶ月プラン"
		}else if(this.plan==2){
		  return "3ヶ月プラン"
		}else if(this.plan==3){
		  return "6ヶ月プラン"
		}else if(this.plan==4){
		  return "12ヶ月プラン"
		}else if(this.plan==5){
		  return "1ヶ月プラン"
		}else if(this.plan==6){
		  return "3ヶ月プラン"
		}else if(this.plan==7){
		  return "6ヶ月プラン"
		}else if(this.plan==8){
		  return "12ヶ月プラン"
		}else if(this.plan==9){
		  return "1ヶ月プラン(ランクそのままキャンペーン)"
		}else if(this.plan==10){
		  return "3ヶ月プラン(ランクそのままキャンペーン)"
		}else if(this.plan==11){
		  return "6ヶ月プラン(ランクそのままキャンペーン)"
		}else if(this.plan==12){
		  return "12ヶ月プラン(ランクそのままキャンペーン)"
		}else if(this.plan==13){
		  return "女性１ヶ月プレミアムプラン"
		}else{
		  return "プランを選択してください"
		}
	  },
	  plan_amount: function(){
		if(this.plan==1){
		  return "2,500"
		}else if(this.plan==2){
		  return "9,900"
		}else if(this.plan==3){
		  return "16,500"
		}else if(this.plan==4){
		  return "26,400"
		}else if(this.plan==5){
		  return "3,000"
		}else if(this.plan==6){
		  return "8,500"
		}else if(this.plan==7){
		  return "14,000"
		}else if(this.plan==8){
		  return "25,000"
		}else if(this.plan==9){
		  return "2,500"
		}else if(this.plan==10){
		  return "9,900"
		}else if(this.plan==11){
		  return "12,000"
		}else if(this.plan==12){
		  return "20,000"
		}else if(this.plan==13){
		  return "1100"
		}else{
		  return ""
		}
	  },
	  site_id_computed: function(){
		if(this.plan==5||this.plan==9||this.plan==13){
		  return "50130901"
		}else if(this.plan==6||this.plan==10){
		  return "50130902"
		}else if(this.plan==7||this.plan==11){
		  return "50130903"
		}else if(this.plan==8||this.plan==12){
		  return "50130904"
		}else{
		  return ""
		}
	  },
	  payment_cycle: function(){
		if(this.plan==5||this.plan==9||this.plan==13){
		  return 30
		}else if(this.plan==6||this.plan==10){
		  return 90
		}else if(this.plan==7||this.plan==11){
		  return 180
		}else if(this.plan==8||this.plan==12){
		  return 360
		}else{
		  return ""
		}
	  },
	},
	methods:{
		setPlan(plan_n) {
		  if(plan_n == 1){
			this.plan = 1
			this.amount = 2500
		  }else if (plan_n == 2){
			this.plan = 2
			this.amount = 9900
		  }else if (plan_n == 3){
			this.plan = 3
			this.amount = 16500
		  }else if (plan_n == 4){
			this.plan = 4
			this.amount = 26400
		  }else if (plan_n == 5){
			this.plan = 5
			this.amount = 3000
		  }else if (plan_n == 6){
			this.plan = 6
			this.amount = 8500
		  }else if (plan_n == 7){
			this.plan = 7
			this.amount = 14000
		  }else if (plan_n == 8){
			this.plan = 8
			this.amount = 25000
		  }else if (plan_n == 9){
			this.plan = 9
			this.amount = 2500
		  }else if (plan_n == 10){
			this.plan = 10
			this.amount = 9900
		  }else if (plan_n == 11){
			this.plan = 11
			this.amount = 12000
		  }else if (plan_n == 12){
			this.plan = 12
			this.amount = 20000
		  }else if (plan_n == 13){
			this.plan = 13
			this.amount = 1100
		  }
		},
		async getAccountData() {
		  const result = await axios.get("/api/payment_information", {
			headers: {
			  uid: this.uid,
			  "access-token": this.access_token,
			  client: this.client,
			},
		  }).catch((e) => {
			e;
		  });
  
		  if (!result) {
			// エラーの場合ログイン画面へ遷移させる
			// this.redirectLogin();
			return;
		  }
		  this.payment_id = result.data.payment_id;
		  this.payment_pass = result.data.payment_pass;
		  this.payment_conf= result.data.payment_conf;
		  this.payment_kind=result.data.payment_kind;
		  this.last_payment_date=result.data.last_payment_date;
		  this.next_payment_date=result.data.next_payment_date;
		  this.course_name=result.data.course_name;
		  this.listed=result.data.listed;
		  this.email = result.data.email;
  
		  if(this.payment_conf == 1){
		   this.$router.push("/home") 
		  }
  
		  this.isLoading = false;
		  setTimeout(this.setCompletedSteps, 1000);
	  },
	  al_validation_check(){
		if(this.$refs["al_form"].validate()){
		  this.al_form_is_valid=true
		} else {
		  this.al_form_is_valid=false
		}
	  },
  
	  purchase() {
		this.isLoading = !this.isLoading;
		if(this.plan==5||this.plan==9||this.plan==13){
		  this.site_id = "50130901"
		}else if(this.plan==6||this.plan==10){
		  this.site_id = "50130902"
		}else if(this.plan==7||this.plan==11){
		  this.site_id = "50130903"
		}else if(this.plan==8||this.plan==12){
		  this.site_id = "50130904"
		}
		window.TokenPay.init(this.site_id);
		window.TokenPay.createToken({
		  number: this.al_form.no, //カード番号
		  name: this.al_form.name, //カード名義
		  expMonth: this.al_form.expm, //カード有効期限(月)
		  expYear: this.al_form.expy, //カード有効期限(年)
		  cvv2: this.al_form.cvv2 //カードCVV2
		}, this.callback); //コールバック関数(トークン取得後に実行)
	  },
  
	  callback(result) {
		if (result.token != null) {
		  this.token = result.token;
		  this.post()
  
		} else {
			//エラー処理
			this.isLoading = !this.isLoading;
			alert('カード情報を正しく入力してください');
		}
	  },
	  async post() {
		const result = await axios({
		method : 'POST',
		url    : "/api/al_payment",
		headers: {'Content-Type': 'application/json',
				uid: this.uid,
				"access-token": this.access_token,
				client: this.client,
			  },
		data   : { 
			  pi:{
				token:this.token,
				site_id:this.site_id,
				campaign:this.listed,
				amount:this.amount,
			  }
		  }
		}).catch((e) => {
		  e
		  this.isLoading = !this.isLoading;
		});
  
		if(!result.data.success){
		  this.message = "入力内容を確認してください"
		  this.isLoading = !this.isLoading;
		}
		if(result.data.success) {
		  this.post_content="";
		  this.al_form.no = '';
		  this.al_form.name = '';
		  this.al_form.expm = '';
		  this.al_form.expy = '';
		  this.al_form.cvv2 = '';
		  // this.getAccountData();
		  this.$router.push({ name: 'Home'});
		  this.message = "更新しました"
		}
	  }
	},
	mounted() {
	  this.uid = localStorage.getItem('uid');
	  this.client = localStorage.getItem('client');
	  this.access_token = localStorage.getItem('access-token');
	  this.sex = localStorage.getItem('sex')
	  this.getAccountData();
	  
	  if (this.uid===null){
		this.$router.push('/login');
	  }
	},
	unmounted() {
	  this.al_form.no = '';
	  this.al_form.name = '';
	  this.al_form.expm = '';
	  this.al_form.expy = '';
	  this.al_form.cvv2 = '';
	}
  }
  </script>