<template>
  <div class="header" style="display:fixed">
    <router-link to="/home">
      <img src="@/assets/logo.png" class="header-logo" />
    </router-link>
    <div class="hamburger-menu">
      <input
        type="checkbox"
        id="menu-btn-check"
        ref="menuBtnCheck"
        :v-model="showContent"
      />
      <label for="menu-btn-check" class="menu-btn" @click="openModal"
        ><span></span
      ></label>

      <div class="menu-content">
        <div>

        </div>
        <div style="height: 100%; padding-top: 10px" v-show="showContent">
          <div style="height: 100%; position: relative; width: 100%">
            <ul v-show="sex == 1">
              <li>
                <router-link to="/home" @click="closeModal"
                  ><Icon
                    name="home"
                    scale="1"
                    style="padding-right: 3px"
                  />ホーム</router-link
                >
              </li>
              <li>
                <router-link to="/notice/index"
                  ><Icon
                    name="bell"
                    scale="1"
                    style="padding-right: 3px"
                  />お知らせ一覧</router-link
                >
              </li>

              <li>
                <router-link to="/form"
                  ><Icon
                    name="user-circle"
                    scale="1"
                    style="padding-right: 3px"
                  />プロフィール</router-link
                >
              </li>
              <li>
                <router-link to="/payment_information"
                  ><Icon
                    name="credit-card"
                    scale="1"
                    style="padding-right: 3px"
                  />決済情報</router-link
                >
              </li>
              <li v-if="payment_conf == 1">
                <router-link to="/review"
                  ><Icon
                    name="comment-alt"
                    scale="1"
                    style="padding-right: 3px"
                  />みんなのレビュー</router-link
                >
              </li>
              <li v-else>
                <span class="disabled-link"
                  ><Icon
                    name="comment-alt"
                    scale="1"
                    style="padding-right: 3px"
                  />みんなのレビュー(決済後利用可)</span
                >
              </li>
              <li v-if="payment_conf == 1">
                <router-link to="/topics"
                  ><Icon
                    name="comments"
                    scale="1"
                    style="padding-right: 3px"
                  />ボード</router-link
                >
              </li>
              <li v-else>
                <span class="disabled-link"
                  ><Icon
                    name="comments"
                    scale="1"
                    style="padding-right: 3px"
                  />ボード(決済後利用可)</span
                >
              </li>
              <li>
                <a
                  href="https://service.seiheki-matching.jp/index"
                  target="”_blank”"
                  ><Icon
                    name="th"
                    scale="1"
                    style="padding-right: 3px"
                  />サービスページ</a
                >
              </li>
              <li>
                <a href="https://itonami.design/media" target="”_blank”"
                  ><Icon
                    name="th"
                    scale="1"
                    style="padding-right: 3px"
                  />イトナミメディア</a
                >
              </li>
                <li v-if="sex==1">

                    <a href="https://service.seiheki-matching.jp/men_qa" target=”_blank”><Icon name="th" scale="1" style="padding-right:3px;"/>Q&A</a>

                </li>

                <li v-else>

                    <a href="https://service.seiheki-matching.jp/women_qa" target=”_blank”><Icon name="th" scale="1" style="padding-right:3px;"/>Q&A</a>

                </li>
            </ul>
            <ul v-show="sex == 2">
              <li>
                <router-link to="home" @click="closeModal"
                  ><Icon
                    name="home"
                    scale="1"
                    style="padding-right: 3px"
                  />ホーム</router-link
                >
              </li>
              <li>
                <router-link to="/notice/index"
                  ><Icon
                    name="bell"
                    scale="1"
                    style="padding-right: 3px"
                  />お知らせ一覧</router-link
                >
              </li>
              <li>
                <router-link to="/hona">

                <Icon
                    name="hashtag"
                    scale="1"
                    style="padding-right: 3px"
                  />運営とメッセージ</router-link>
              </li>
              <li>
                <a href="https://twitter.com/i/communities/1498666591294488578" target="”_blank”"
                  ><Icon
                    name="hashtag"
                    scale="1"
                    style="padding-right: 3px"
                  />女性コミュニティ</a
                >
              </li>              
              <li v-if="age_conf == 2">
                <router-link to="recommends"
                  ><Icon
                    name="thumbs-up"
                    scale="1"
                    style="padding-right: 3px"
                  />レコメンド</router-link
                >
              </li>
              <li>
                <router-link to="/payment_information"
                  ><Icon
                    name="credit-card"
                    scale="1"
                    style="padding-right: 3px"
                  />決済情報</router-link
                >
              </li>
              <li>
                <router-link to="femaleform"
                  ><Icon
                    name="user-circle"
                    scale="1"
                    style="padding-right: 3px"
                  />プロフィール</router-link
                >
              </li>
              <li v-if="age_conf == 2">
                <router-link to="review"
                  ><Icon
                    name="comment-alt"
                    scale="1"
                    style="padding-right: 3px"
                  />みんなのレビュー</router-link
                >
              </li>
              <li v-else>
                <span class="disabled-link"
                  ><Icon
                    name="comment-alt"
                    scale="1"
                    style="padding-right: 3px"
                  />みんなのレビュー</span
                >
              </li>
              <li v-if="age_conf == 2">
                <router-link to="topics"
                  ><Icon
                    name="comments"
                    scale="1"
                    style="padding-right: 3px"
                  />ボード</router-link
                >
              </li>
              <li v-else>
                <span class="disabled-link"
                  ><Icon
                    name="comments"
                    scale="1"
                    style="padding-right: 3px"
                  />ボード</span
                >
              </li>
              <li>
                <a
                  href="https://service.seiheki-matching.jp/index"
                  target="”_blank”"
                  ><Icon
                    name="question-circle"
                    scale="1"
                    style="padding-right: 3px"
                  />サービスページ</a
                >
              </li>
              <li>
                <a href="https://itonami.design/media" target="”_blank”"
                  ><Icon
                    name="th"
                    scale="1"
                    style="padding-right: 3px"
                  />イトナミメディア</a
                >
              </li>
                <li v-if="sex==1">

                    <a href="https://service.seiheki-matching.jp/men_qa" target=”_blank”><Icon name="th" scale="1" style="padding-right:3px;"/>Q&A</a>

                </li>

                <li v-else>

                    <a href="https://service.seiheki-matching.jp/women_qa" target=”_blank”><Icon name="th" scale="1" style="padding-right:3px;"/>Q&A</a>

                </li>
            </ul>
            <div class="company-info-wrapper" v-show="showContent">
              <img src="@/assets/logo.png" class="header-logo-mini" />
              <br />
              <div>
                <Logout v-show="sex == 1 || sex == 2" />
                <div v-if="sex == 1">
                  <p>
                    <a
                      href="https://service.seiheki-matching.jp/MEN_userpolicy"
                      target="_blank"
                      >利用規約</a
                    >
                    <a
                      href="https://service.seiheki-matching.jp/MEN_policy"
                      target="_blank"
                      >プライバシーポリシー</a
                    >
                    <a href="https://itonami.co.jp/" target="_blank">運営会社</a
                    ><br /><a
                      href="https://service.seiheki-matching.jp/MEN_tokutei"
                      target="_blank"
                      >特定商取引法に基づく表記</a
                    >
                  </p>
                </div>
                <div v-if="sex == 2">
                  <p>
                    <a
                      href="https://service.seiheki-matching.jp/WOMEN_userpolicy"
                      target="_blank"
                      >利用規約</a
                    >
                    <a
                      href="https://service.seiheki-matching.jp/WOMEN_policy"
                      target="_blank"
                      >プライバシーポリシー</a
                    >
                    <a href="https://itonami.co.jp/" target="_blank">運営会社</a
                    ><br /><a
                      href="https://service.seiheki-matching.jp/WOMEN_tokutei"
                      target="_blank"
                      >特定商取引法に基づく表記</a
                    >
                  </p>
                </div>
                <div v-else>
                  <p>
                    <a
                      href="https://service.seiheki-matching.jp/MEN_userpolicy"
                      target="_blank"
                      >利用規約</a
                    >
                    <a
                      href="https://service.seiheki-matching.jp/MEN_policy"
                      target="_blank"
                      >プライバシーポリシー</a
                    >
                    <a href="https://itonami.co.jp/" target="_blank">運営会社</a
                    ><br /><a
                      href="https://service.seiheki-matching.jp/MEN_tokutei"
                      target="_blank"
                      >特定商取引法に基づく表記</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-overlay" v-show="showContent"></div>

    <div id="over_lay" v-show="is_modal_qa">
      <div id="qa_content">
        <div class="qa_content_header">
          <div class="qa_content_header_title">
            <Icon name="comments" scale="1.5" style="padding-right: 3px" />
            よくある質問と答え
          </div>
          <div class="qa_content_header_btn">
            <button @click="is_modal_qa = false">
              <Icon name="window-close" scale="2" class="btn-header" />
            </button>
          </div>
        </div>
        <div class="qa_content_body">
          <ModalQa v-if="is_modal_content"></ModalQa>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "../../util/axios";
import Logout from "@/views/Logout.vue";
import "vue-awesome/icons";
import Icon from "vue-awesome/components/Icon";
import ModalQa from "../views/QA.vue";

export default {
  name: "Header",
  data() {
    return {
      twitter_account_no: null,
      showContent: false,
      sex: "",
      show: false,
      payment_conf: 0,
      age_conf: "",
      uid: "",
      client: "",
      access_token: "",
      is_modal_qa: false,
      is_modal_content: true,
      is_show_modals:false,
    };
  },
  mounted() {
    this.sex = localStorage.getItem("sex");
    this.payment_conf  = localStorage.getItem("payment_conf");

    // this.getAccountData();
    window.addEventListener('resize', this.handleResize)
  },
  created(){
      if(window.innerWidth > 576){
        this.is_show_modals = true;
      }
      else{
        this.is_show_modals = false;
      }
  },
  methods: {
    openModal: function () {
      this.showContent = true;
    },
    closeModal: function () {
      this.showContent = false;
      // this.$refs.menuBtnCheck
    },
    handleResize: function() {
      if(window.innerWidth > 576){
        this.is_show_modals = true;
      }
      else{
        this.is_show_modals = false;
      }
    },
    setQaModal(){
      this.is_modal_qa = true
      this.is_modal_content = false
      this.$nextTick(() => (this.is_modal_content = true))
    },
    async getAccountData() {
      const result = await axios
        .get("/api/account", {
          headers: {
            uid: this.uid,
            "access-token": this.access_token,
            client: this.client,
          },
        })
        .catch((e) => {
          // console.error(e);
          e;
        });

      if (!result) {
        // エラーの場合ログイン画面へ遷移させる
        return;
      }
      if (!result.data.email) {
        // エラーの場合ログイン画面へ遷移させる
        return;
      }

      this.email = result.data.email;
      this.twitterid = result.data.twitterid;
      this.data_all = result.data;
      this.sex = result.data.sex;
      localStorage.setItem("sex", this.sex);
      this.rank = result.data.rank;
      this.matching_count = result.data.matching_count;
      this.recommend_count = result.data.recommend_count;
      this.payment_conf = result.data.payment_conf;
      this.age_conf = result.data.age_conf;
      this.twitter_account_no = result.data.twitter_account_no;
    },
  },
  watch: {
    $route(to, from) {
      // ルートの変更の検知...
      if (to != from) {
        this.uid = localStorage.getItem("uid");
        this.client = localStorage.getItem("client");
        this.access_token = localStorage.getItem("access-token");
        this.payment_conf = localStorage.getItem("payment_conf");
        this.closeModal();
        this.$refs.menuBtnCheck.checked = false;
        this.showContent = false;
        if (!["Login", "SignUp"].includes(this.$route.name)) {
          this.getAccountData();
        } else {
          this.sex = null;
        }

        if (this.showContent == true) {
          this.showContent = !this.showContent;
        }
      }
    },
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  beforeRouteLeave() {
    this.closeModal();
    this.showContent = false;
  },
  components: {
    Logout,
    Icon,
    ModalQa,
  },
};
</script>


<style scoped>
p a {
  font-size: 0.8rem;
  color: #3d3d3d;
  text-decoration: none;
}
p a:hover {
  text-decoration: none;
}
.btn-qa {
  align-content: center;
  align-items: center;
  background: #f85b8c;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid #ffffff;
  border-radius: 320px;
  border-right: 6px solid #ffffff;
  border-top: 6px solid #ffffff;
  bottom: 20px;
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 74px;
  justify-content: center;
  left: -100px;
  margin: 0 0 0 0;
  padding: 0px 0px 0px 0px;
  position: absolute;
  right: 20px;
  top: auto;
  width: 74px;
  max-width: 100%;
}
.btn-qa-text {
  color: #ffffff;
  font-family: Lato;
  font-size: 16px;
  font-weight: 900;
  height: auto;
  letter-spacing: 0.1em;
  line-height: 1.4;
  text-align: center;
  width: auto;
  max-width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  overflow: visible;
  word-break: break-word;
  word-wrap: anywhere;
  vertical-align: middle;
  padding-top: 20px;
}
#over_lay {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;
}
#qa_content {
  z-index: 100;
  align-content: center;
  align-items: center;
  background: #ebebf5;
  border-radius: 10px;
  bottom: 10px;
  box-shadow: 0px 6px 15px 0px rgb(0 0 0 / 20%);
  flex: none;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 600px;
  justify-content: flex-start;
  left: auto;
  margin: 0 0 0 0;
  opacity: 1;
  padding: 20px;
  position: fixed;
  right: 11px;
  top: auto;
  width: 440px;
  max-width: 100%;
}
.qa_content_header {
  align-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 10px 10px 0px 0px;
  flex: none;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 50px;
  justify-content: space-between;
  left: 0;
  margin: 0 0 0 0;
  position: absolute;
  top: 0;
  width: 440px;
  max-width: 100%;
  display: inline-block;
}
.qa_content_header_title {
  color: #333;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  width: auto;
  max-width: calc(100% - 5px);
  justify-content: center;
  float: left;
  padding: 15px;
}
.qa_content_header_btn {
  float: right;
  padding: 10px;
}
.btn-header {
  color: #805de3;
}
.qa_content_body{
  height: 550px;
  width: 420px;
  padding-top:20px;
  margin-top:30px;
  overflow-y:scroll;
}
@media (max-width: 480px) {
  .company-info-wrapper {
    /*display: fixed;*/
    position: absolute;
    bottom: 0px;
    height: 120px;
    width: 100%;
    background: white;
    justify-content: center;
  }

  .hamburger-menu {
    /*  position: sticky;
  text-align: right;
  margin-right: 0;
  background: #775DDF;
  height: 80px;
  width: 80px;
  transition:background-color 0.5s;*/
  }
  .hamburger-menu:hover {
    background: #d85b89;
  }
  .header {
    position: fixed;
    display: flex;
    align-items: center;
    margin: auto;
    z-index: 100;
    width: 100%;
    top: 0;
    height: 50px;
    background: rgba(255, 255, 255, 0.8);
    /*background: red;*/
    /*#FAFAFD;*/
  }
  .menu-btn {
    position: fixed;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 90;
    background: #775ddf;
    height: 50px;
    width: 50px;
    transition: background-color 0.5s;
  }
  .header-logo {
    padding-left: 15px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
  }
}
@media (min-width: 480px) {
  .company-info-wrapper {
    display: fixed;
    position: absolute;
    bottom: 0;
    height: 100px;
    width: 100%;
    background: white;
    justify-content: center;
  }

  .hamburger-menu {
    /*  position: sticky;
  text-align: right;
  margin-right: 0;
  background: #775DDF;
  height: 80px;
  width: 80px;
  transition:background-color 0.5s;*/
  }
  .hamburger-menu:hover {
    background: #d85b89;
  }
  .header {
    position: fixed;
    display: flex;
    align-items: center;
    margin: auto;
    z-index: 100;
    width: 100%;
    top: 0;
    height: 80px;
    background: rgba(255, 255, 255, 0.8);
    /*#FAFAFD;*/
  }
  .menu-btn {
    position: fixed;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 90;
    background: #775ddf;
    height: 80px;
    width: 80px;
    transition: background-color 0.5s;
  }
  .header-logo {
    padding-left: 50px;
    height: 50px;
    display: inline-block;
    vertical-align: middle;
  }
}

.logo-seiheki {
  color: #6c35d9;
}
.logo-matching {
  color: #f54380;
}

.header-logo-mini {
  margin-top: 10px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
}
.modal-overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  /*display: none;*/
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}
#menu-modal {
  position: fixed;
  top: 0;
  right: 0;
  background: gray;
  height: 100%;
  width: 20vw;
  display: none;
}

.menu-btn:hover {
  background: #d85b89;
}
.menu-btn span,
.menu-btn span:before,
.menu-btn span:after {
  content: "";
  display: block;
  height: 3px;
  width: 25px;
  border-radius: 3px;
  background-color: #ffffff;
  position: absolute;
}
.menu-btn span:before {
  bottom: 8px;
}
.menu-btn span:after {
  top: 8px;
}
#menu-btn-check {
  display: none;
}
#menu-btn-check:checked ~ .menu-btn span {
  background-color: rgba(
    255,
    255,
    255,
    0
  ); /*メニューオープン時は真ん中の線を透明にする*/
}
#menu-btn-check:checked ~ .menu-btn span::before {
  bottom: 0;
  transform: rotate(45deg);
}
#menu-btn-check:checked ~ .menu-btn span::after {
  top: 0;
  transform: rotate(-45deg);
}

#menu-btn-check:checked ~ .menu-content {
  left: calc(100% - 300px); /*メニューを画面内へ*/
}

#menu-btn-check:checked ~ .modal-overlay {
  display: true;
}

.menu-content {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 80;
  background-color: #3584bb;
}
.menu-content ul {
  text-align: left;
  padding: 70px 20px 0;
}
.menu-content ul li {
  border-bottom: dotted 1px #ffffff;
  list-style: none;
}
.menu-content ul li a {
  display: block;
  width: 100%;
  font-size: 15px;
  font-weight: 800;
  box-sizing: border-box;
  color: #ffffff !important;
  text-decoration: none;
  padding: 9px 15px 10px 0;
  position: relative;
}
.menu-content ul li a::before {
  content: "";
  width: 7px;
  height: 7px;
  border-top: solid 2px #ffffff;
  border-right: solid 2px #ffffff;
  transform: rotate(45deg);
  position: absolute;
  right: 11px;
  top: 16px;
}

.menu-content {
  max-width: 300px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 100%; /*leftの値を変更してメニューを画面外へ*/
  z-index: 80;
  background: linear-gradient(
    315deg,
    rgba(250, 160, 152, 0.9),
    rgba(248, 96, 141, 0.9) 34%,
    rgba(113, 129, 237, 0.9) 69%,
    rgba(118, 82, 230, 0.9)
  );
  transition: all 0.5s; /*アニメーション設定*/
}

.disabled-link {
  display: block;
  width: 100%;
  font-size: 15px;
  font-weight: 800;
  box-sizing: border-box;
  color: #3f3f3f;
  text-decoration: none;
  padding: 9px 15px 10px 0;
  position: relative;
}
</style>